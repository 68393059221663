import React, { useContext, useState, useEffect } from 'react';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import ErrorMessage from '../../../../../../components/errorMessage';
import { Calendar } from 'primereact/calendar';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useSelector } from 'react-redux';
import {
  monthYearSlashRegex,
  onlyNumber,
} from '../../../../../../utils/patterns/regex.pattern';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import {
  durationOptions,
  durationTypeOptions,
} from '../../../../../../assets/global';
import {
  capitalizeFirstLetter,
  capitalizeWords,
} from '../../../../../../utils/constant';
import InputDateTextFormat from './inputDateTextFormat';

const conditions = [
  { name: 'Diabetes', isChecked: false, date: '' },
  { name: 'Hypertension', isChecked: false, date: '' },
  { name: 'Heart Disease', isChecked: false, date: '' },
  { name: 'Thyroid Disease', isChecked: false, date: '' },
  { name: 'Liver Disease', isChecked: false, date: '' },
  { name: 'Seizure', isChecked: false, date: '', frequency: '', per: '' },
];
interface pastMedicalHistoryProps {
  onSave?: Function;
}
const PastMedicalHistory: React.FC<pastMedicalHistoryProps> = ({
  onSave = () => {},
}) => {
  const [medicalHistory, setMedicalHistory] = useState(conditions);
  const patient_Id = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );
  const validationSchema = yup.object().shape(
    medicalHistory.reduce((acc: any, condition: any) => {
      if (condition.isChecked) {
        if (condition.name === 'Seizure') {
          acc[condition.name] = yup.object().shape({
            date: yup.string().required(`${condition.name} date is required`),
            // .matches(monthYearSlashRegex, 'Date format mismatching'),
            frequency: yup
              .object({
                name: yup.number().required('Frequency is required'),
              })
              .nullable()
              .required('Type is required'),

            per: yup
              .object({
                name: yup.string().trim().required('Limit is required'),
              })
              .nullable()
              .required('Limit is required'),
            // yup.string().required('Limit is required'),
          });
        } else {
          acc[condition.name] = yup
            .string()
            .trim()
            .required(`${condition.name} date is required`);
        }
      }
      return acc;
    }, {}),
  );

  const { getData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: medicalHistory.reduce((acc: any, condition: any) => {
      acc[`${condition.name}_isCheck`] = condition.isChecked ? 'Yes' : 'No';
      acc[condition.name] =
        condition.name === 'Seizure'
          ? {
              date: condition.date || '',
              frequency:
                {
                  name: condition.frequency || undefined,
                  code: condition.frequency || undefined,
                } || null,
              per: { name: condition.per, code: condition.per } || '',
            }
          : condition.date || '';
      return acc;
    }, {}),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
    clearErrors,
    control,
    formState: { errors, isDirty },
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        label: [
          {
            pasthistory: medicalHistory.map((item) => {
              const itemName = item.name.toLowerCase();
              if (itemName === 'seizure') {
                if (!item.isChecked) {
                  return {
                    name: itemName,
                    is_check: item.isChecked ? 'yes' : 'no',
                  };
                } else
                  return {
                    name: itemName,
                    since: item.date,
                    is_check: item.isChecked ? 'yes' : 'no',
                    frequency: item.frequency?.code || item.frequency,
                    per: item.per?.code || item.per,
                  };
              } else if (!item.isChecked) {
                return {
                  name: itemName,
                  is_check: item.isChecked ? 'yes' : 'no',
                };
              } else
                return {
                  name: itemName,
                  is_check: item.isChecked ? 'yes' : 'no',
                  since: item.date,
                };
            }),
          },
        ],
      };
      let url = end_points.pastMedicalCreateApi.url; // Add the appropriate URL
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await postData(url, payload);
      if (response.status === 200) {
        onSave();
        //
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleRadioChange = (index: any, value: any) => {
    const newHistory = [...medicalHistory];
    newHistory[index].isChecked = value === 'Yes';
    setMedicalHistory(newHistory);

    if (value === 'No') {
      clearErrors(newHistory[index].name);
    }
  };

  const handleDateChange = (index: any, value: any) => {
    const newHistory = [...medicalHistory];
    newHistory[index].date = value;
    setMedicalHistory(newHistory);
  };

  const handleFrequencyChange = (index: any, value: any) => {
    const newHistory = [...medicalHistory];
    newHistory[index].frequency = value;
    setMedicalHistory(newHistory);
  };

  const handlePerChange = (index: any, value: any) => {
    const newHistory = [...medicalHistory];
    newHistory[index].per = value;
    setMedicalHistory(newHistory);
  };
  const getPatientHistory = async () => {
    try {
      showLoader();
      let url = end_points.pastMedicalGetApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const newHistory = response.data?.data?.label?.[0]?.pasthistory?.map(
          (item: any) => {
            const itemName = capitalizeWords(item.name);
            if (itemName === 'Seizure') {
              return {
                name: itemName,
                isChecked: item?.is_check?.toLowerCase() === 'yes',
                date: item.since,
                frequency: item.frequency,
                per: item.per,
              };
            } else
              return {
                name: itemName,
                isChecked: item?.is_check?.toLowerCase() === 'yes',
                date: item.since,
              };
          },
        );
        newHistory.forEach((condition: any) => {
          setValue(
            `${condition.name}_isCheck`,
            condition.isChecked ? 'Yes' : 'No',
          );

          if (condition.name === 'Seizure') {
            setValue(
              `${condition.name}.date`,
              condition.date || '',
              // moment(condition.date, 'MM/YYYY').format('YYYY-MM') || '',
            );
            setValue(
              `${condition.name}.frequency`,
              {
                name: condition.frequency,
                code: condition.frequency,
              } || null,
            );
            setValue(
              `${condition.name}.per`,
              {
                name: capitalizeFirstLetter(condition.per),
                code: condition.per,
              } || null,
            );
          } else
            setValue(
              condition.name,
              condition.date,
              // moment(condition.date, 'MM/YYYY').format('YYYY-MM') || '',
            );
        });
        setMedicalHistory(newHistory);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getPatientHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_Id]);
  return (
    <div
      className="tab-pane fade show active"
      id="past_medical"
      role="tabpanel"
      aria-labelledby="past-medical"
    >
      <div className="row">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12">
              <div className="card medical-history-card">
                <div className="card-body">
                  <div className="past-surgeries past-medical-info">
                    <ul className="nav">
                      <li>
                        <h6>Past Medical History</h6>
                      </li>
                    </ul>
                  </div>
                  {medicalHistory.map((condition, index) => (
                    <div
                      key={condition.name}
                      className="past-surgeries past-surgerie-left"
                    >
                      <ul className="nav past-surgeries-nav">
                        <li>
                          <p>{condition.name}</p>
                        </li>
                        <li>
                          <div className="surgerie-flex">
                            <Controller
                              name={`${condition.name}_isCheck`}
                              control={control}
                              defaultValue={'No'}
                              render={({ field }) => (
                                <div className="custom-radio patient-radio-btn">
                                  <label className="custom_radio">
                                    <input
                                      type="radio"
                                      {...field}
                                      value="Yes"
                                      checked={field.value === 'Yes'}
                                      onChange={() => {
                                        field.onChange('Yes');
                                        handleRadioChange(index, 'Yes');
                                      }}
                                    />
                                    <span className="checkmark" /> Yes
                                  </label>
                                  <label className="custom_radio">
                                    <input
                                      type="radio"
                                      {...field}
                                      value="No"
                                      checked={field.value === 'No'}
                                      onChange={() => {
                                        field.onChange('No');
                                        handleRadioChange(index, 'No');
                                      }}
                                    />
                                    <span className="checkmark" /> No
                                  </label>
                                </div>
                              )}
                            />
                            {condition.isChecked && (
                              <div className="since-input">
                                <span>Since</span>
                                <Controller
                                  name={
                                    condition.name === 'Seizure'
                                      ? `${condition.name}.date`
                                      : condition.name
                                  }
                                  control={control}
                                  render={({ field }) => (
                                    <div>
                                      <InputDateTextFormat
                                        field={field}
                                        index={index}
                                        condition={condition}
                                        handleDateChange={handleDateChange}
                                        setValue={setValue}
                                        className={`form-control datetimepicker ${
                                          // condition.name === 'Seizure'
                                          errors[condition.name]?.date ||
                                          errors[condition.name]
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        controlName={
                                          condition.name === 'Seizure'
                                            ? `${condition.name}.date`
                                            : condition.name
                                        }
                                        trigger={trigger}
                                      />
                                      <ErrorMessage
                                        value={
                                          condition.name === 'Seizure'
                                            ? errors[condition.name]?.date
                                            : errors[condition.name]
                                        }
                                      />
                                    </div>
                                    // <Calendar
                                    //   appendTo={'self'}
                                    //   type="date"
                                    //   {...field}
                                    //   className="form-control datetimepicker"
                                    //   placeholder="MM/YYYY"
                                    //   onChange={(e) => {
                                    //     field.onChange(e.target.value);
                                    //     setValue(
                                    //       condition.name === 'Seizure'
                                    //         ? [condition?.name]?.date
                                    //         : condition.name,
                                    //       e.target.value,
                                    //     );
                                    //     handleDateChange(index, e.target.value);
                                    //   }}
                                    // />
                                    // <input
                                    //   type="text"
                                    //   {...field}
                                    //   className="form-control datetimepicker"
                                    //   placeholder="MM/YYYY"
                                    //   value={field.value}
                                    //   onChange={(e) => {
                                    //     let value = e.target.value;

                                    //     if (
                                    //       value.length === 2 &&
                                    //       !value.includes('/')
                                    //     ) {
                                    //       if (
                                    //         Number(value) >= 1 &&
                                    //         Number(value) <= 12
                                    //       ) {
                                    //         value = `${value}/`;
                                    //       } else {
                                    //         value = '';
                                    //       }
                                    //     }

                                    //     if (value.length > 7) {
                                    //       value = value.slice(0, 7);
                                    //     }

                                    //     field.onChange(value);
                                    //     setValue(
                                    //       condition.name === 'Seizure'
                                    //         ? [condition?.name]?.date
                                    //         : condition.name,
                                    //       e.target.value,
                                    //     );
                                    //     handleDateChange(index, value);
                                    //   }}
                                    // />
                                  )}
                                />
                              </div>
                            )}
                            {condition.name === 'Seizure' &&
                              condition.isChecked && (
                                <>
                                  <div className="since-input">
                                    <span>Frequency</span>
                                    <Controller
                                      name={`${condition.name}.frequency`}
                                      control={control}
                                      render={({ field }) => (
                                        <div className="d-flex flex-column">
                                          <Dropdown
                                            {...field}
                                            placeholder="No. of Times"
                                            options={durationOptions}
                                            value={field?.value}
                                            onBlur={() =>
                                              trigger(
                                                `${condition.name}.frequency`,
                                              )
                                            }
                                            className={`patient-reg-select ${errors[`${condition.name}.frequency`] ? 'is-invalid' : ''}`}
                                            optionLabel="name"
                                            onChange={(e) => {
                                              field.onChange(e.target.value);
                                              handleFrequencyChange(
                                                index,
                                                e.target.value,
                                              );
                                            }}
                                          />
                                          <ErrorMessage
                                            value={
                                              errors[condition.name]?.frequency
                                                ?.name ||
                                              errors[condition.name]?.frequency
                                            }
                                          />
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className="since-input per-since-input">
                                    <span>Per</span>
                                    <Controller
                                      name={`${condition.name}.per`}
                                      control={control}
                                      render={({ field }) => (
                                        <div className="d-flex flex-column">
                                          <Dropdown
                                            {...field}
                                            placeholder="Month"
                                            options={durationTypeOptions}
                                            value={field?.value}
                                            onBlur={() =>
                                              trigger(`${condition.name}.per`)
                                            }
                                            className={`patient-reg-select ${errors[`${condition.name}.per`] ? 'is-invalid' : ''}`}
                                            optionLabel="name"
                                            onChange={(e) => {
                                              field.onChange(e.target.value);
                                              handlePerChange(
                                                index,
                                                e.target.value,
                                              );
                                            }}
                                          />
                                          <ErrorMessage
                                            value={
                                              errors[condition.name]?.per
                                                ?.name ||
                                              errors[condition.name]?.per
                                            }
                                          />
                                        </div>
                                        // <input
                                        //   type="text"
                                        //   {...field}
                                        //   className="form-control"
                                        //   placeholder="Month"
                                        //   onChange={(e) => {
                                        //     field.onChange(e.target.value);
                                        //     handlePerChange(
                                        //       index,
                                        //       e.target.value,
                                        //     );
                                        //   }}
                                        // />
                                      )}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))}
                  <div className="past-medical-btn">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default PastMedicalHistory;
