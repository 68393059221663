import React, { useEffect, useRef, useState, useContext } from 'react';
import OT from '@opentok/client';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { end_points } from '../../../../../../services/end_point/end_points';
import {
  setCallJoined,
  setPrescriptionDrugTableData,
  setSessionDetails,
  setSymtomsFlowData,
} from '../../../../../../core/redux/commonSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import {
  ApiServiceContext,
  routes,
} from '../../../../../../utils/shared.module';
import {
  endcallIcon,
  fullscreenIcon,
  screenshotIcon,
} from '../../../../../../utils/imagepath';
import { useNavigate } from 'react-router-dom';

const JoinCall = () => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const dispatch = useDispatch();

  const sessionDetail = useSelector(
    (state: any) => state.common.sessionDetails,
  );
  const idCall = useSelector((state: any) => state.common.flowData?._id);
  const idParam = useSelector(
    (state: any) => state.common.flowData?.paramedic_id,
  );
  const aptId = sessionDetail?._id || idCall;
  const paraId = sessionDetail?.paramedic_id || idParam;
  const { putData } = useContext(ApiServiceContext);
  const [otSession, setOtSession] = useState<any>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null,
  );

  const [recordingChunks, setRecordingChunks] = useState<Blob[]>([]);
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [endCallBool, setEndCallBool] = useState<boolean>(false);
  const publisherRef = useRef<any>(null);
  const subscriberRef = useRef<any>(null);
  const closeModel: any = useRef(null);

  const screenShotValidation = yup.object().shape({
    description: yup.string().trim().required('Description is required'),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(screenShotValidation),
    defaultValues: { description: '' },
  });
  const navigate = useNavigate();
  useEffect(() => {
    const API_KEY =
      // '98203cbd-9b7b-4116-8f4c-0f4c591b45ae';
      sessionDetail?.vonage_api_key;
    const SESSION_ID =
      // '1_MX45ODIwM2NiZC05YjdiLTQxMTYtOGY0Yy0wZjRjNTkxYjQ1YWV-fjE3MjQ0MjA1OTM2MDl-NElYcnZpcjZPYkQ1Z1N4UUx2VG96ZWxrfn5-';
      sessionDetail?.session_id;
    const TOKEN =
      // 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6InNlc3Npb24uY29ubmVjdCIsInNlc3Npb25faWQiOiIxX01YNDVPREl3TTJOaVpDMDVZamRpTFRReE1UWXRPR1kwWXkwd1pqUmpOVGt4WWpRMVlXVi1makUzTWpRME1qQTFPVE0yTURsLU5FbFljblpwY2paUFlrUTFaMU40VVV4MlZHOTZaV3hyZm41LSIsInJvbGUiOiJtb2RlcmF0b3IiLCJpbml0aWFsX2xheW91dF9jbGFzc19saXN0IjoiZm9jdXMiLCJleHAiOjE3MjUwMjUzOTMuNjYsInN1YiI6InZpZGVvIiwiYWNsIjp7InBhdGhzIjp7Ii9zZXNzaW9uLyoqIjp7fX19LCJjb25uZWN0aW9uX2RhdGEiOiJzZW5kZXJfaWQ9dW5kZWZpbmVkLHJlY2VpdmVyX2lkPXVuZGVmaW5lZCIsImp0aSI6ImQ2YTQ1ZTUzLWExNjktNDI2ZS1iNDg0LTJmMDNhZjhjOGRkMyIsImlhdCI6MTcyNDQyMDU5MywiYXBwbGljYXRpb25faWQiOiI5ODIwM2NiZC05YjdiLTQxMTYtOGY0Yy0wZjRjNTkxYjQ1YWUifQ.J_37uyK9ICptPWolSboOnqd5erlPCYst3QzUNFd2a6Yzc2UyzEelJtowX5A85ExD09Gtk1pzSJCI8FkCuVyU1iGwPJaNL6RvsXWCe9JmCz736bnr1FK3etEDGL6LNYAdE9gE9xiyn9Ra71Jp16cz51_PgUEG5k23ezt6RSP2mlIHXQkIeGg7lDFRys-QQvvgobCtYWslU45h4cV-d8rtHISIRw7HebyWjSLkOG-iZ02B99va4606qrJ0e8FDwiNGIuyR_C2DIR8e2bpUf9yeGomYmxXwetQ4fpb3VDVpZZ79ssApAQFIL63-Ct-4xxI92BY79Do2HgL4h9tBbQ9DHQ';
      sessionDetail?.vonage_token;
    const session = OT.initSession(API_KEY, SESSION_ID);
    setOtSession(session);

    session.on('streamCreated', (event) => {
      const subscriber = session.subscribe(
        event.stream,
        'subscriber',
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        (error: any) => {
          if (error) {
            console.error('Error subscribing to stream:', error);
          } else {
            subscriberRef.current = subscriber;
            startRecording(event.stream);
          }
        },
      );
    });

    session.connect(TOKEN, (error) => {
      if (error) {
        console.error('Failed to connect:', error);
        return;
      }

      const publisher = OT.initPublisher(
        'publisher',
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        handleError,
      );
      session.publish(publisher, handleError);
      publisherRef.current = publisher;
    });

    return () => {
      if (session) session.disconnect();
      if (mediaRecorder) mediaRecorder.stop();
    };
  }, [sessionDetail]);

  const handleError = (error: any) => {
    if (error) {
      console.error('Error:', error.message);
    }
  };

  const startRecording = (stream: any) => {
    try {
      // console.log('recording start');

      const videoElement = document.querySelector(
        '#subscriber video',
      ) as HTMLVideoElement;
      if (!videoElement) {
        console.error('No video element found');
        return;
      }
      const mediaStream = videoElement.srcObject as MediaStream;
      if (!mediaStream) {
        console.error('No valid MediaStream found in the video element');
        return;
      }
      const recorder = new MediaRecorder(mediaStream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordingChunks((prev) => {
            // console.log(prev, event.data, 'event.data');

            return [...prev, event.data];
          });
        }
      };

      recorder.start();
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };
  const endCallNotify = async () => {
    try {
      showLoader();
      let url = end_points.disconnectVideoCall.url;
      url += `/${aptId}`;
      const payload = {
        paramedic_id: paraId,
        status: 'call decline',
      };

      const response = await putData(url, payload);
      if (response?.status === 200) {
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };
  const endCall = () => {
    setEndCallBool(true);
    if (publisherRef.current) publisherRef.current.destroy();
    if (subscriberRef.current) subscriberRef.current.destroy();
    if (otSession) otSession.disconnect();
    if (mediaRecorder) mediaRecorder.stop();
    endVideoCall();
    endCallNotify();
  };

  const endVideoCall = async () => {
    showLoader();
    try {
      await putData(`${end_points.videoCallEnd.url}/${aptId}`, {
        paramedic_id: paraId,
        ...sessionDetail,
      });
    } finally {
      dispatch(setPrescriptionDrugTableData([]));
      dispatch(setSymtomsFlowData({}));
      hideLoader();
    }
  };

  const takeScreenshot = () => {
    const videoElement = document.querySelector(
      '#subscriber video',
    ) as HTMLVideoElement;
    if (videoElement) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const screenshotDataUrl = canvas.toDataURL('image/png');
      setScreenshot(screenshotDataUrl);
      document.getElementById('triggerModalButton')?.click();
    } else {
      console.error('Video element not found.');
    }
  };

  const onSubmit = async (data: any) => {
    showLoader();
    try {
      if (screenshot) {
        const formData = new FormData();
        formData.append(
          'screenshot_image',
          base64ToFile(screenshot, 'screenshot.png'),
        );
        formData.append('description', data.description);
        const response = await putData(
          `${end_points.screenShotSave.url}/${aptId}`,
          formData,
        );
        if (response?.status === 200) {
          closeModel.current.click();
          reset();
        }
      }
    } finally {
      hideLoader();
    }
  };

  const toggleFullscreen = () => {
    const videoContainer: any = document.querySelector('.video-container');

    if (!document.fullscreenElement) {
      if (videoContainer?.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer?.mozRequestFullScreen) {
        // Firefox
        videoContainer.mozRequestFullScreen();
      } else if (videoContainer?.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        videoContainer.webkitRequestFullscreen();
      } else if (videoContainer?.msRequestFullscreen) {
        // IE/Edge
        videoContainer.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  const base64ToFile = (base64String: any, filename: string) => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  const sendVideoChunks = async (recordingChunk: any = recordingChunks) => {
    // console.log(recordingChunks, 'recordingChunks');

    const videoBlob = new Blob(recordingChunk, { type: 'video/webm' });
    const videoFile = new FormData();
    videoFile.append(
      'screenrecording_attachment',
      videoBlob,
      'call-recording.webm',
    );
    // console.log('videoBlob', videoBlob);
    // const videoUrl = URL.createObjectURL(videoBlob);

    // const downloadLink = document.createElement('a');
    // downloadLink.href = videoUrl;
    // downloadLink.download = 'call-recording.webm';

    // document.body.appendChild(downloadLink);
    // downloadLink.click();

    // URL.revokeObjectURL(videoUrl);
    // document.body.removeChild(downloadLink);
    try {
      let url = end_points.vedioRecordingApi.url;
      url += `/${aptId}`;
      const res = await putData(url, videoFile);
      if (res.status) {
        // console.log('video sent successfully');
      }
    } catch (error) {
      console.error('Error sending video to the server:', error);
    } finally {
      if (endCallBool) {
        // console.log(endCallBool, 'endCallBool');
        dispatch(setSessionDetails(undefined));
        dispatch(setCallJoined(undefined));
        setEndCallBool(false);
        navigate(routes.pastConsults.path);
      }
    }
  };
  useEffect(() => {
    // console.log(recordingChunks, 'recordingChunks');
    if (recordingChunks?.length > 0 && endCallBool) {
      sendVideoChunks(recordingChunks);
    } else if (endCallBool) {
      // console.log(endCallBool, 'endCallBool');
      dispatch(setSessionDetails(undefined));
      dispatch(setCallJoined(undefined));
      setEndCallBool(false);
      navigate(routes.pastConsults.path);
    }
  }, [recordingChunks, endCallBool]);
  return (
    <>
      <div className="video-container">
        <div id="publisher" className="video-item" />
        <div id="subscriber" className="video-item" />
        <div className="video-btn">
          <div className="video-btn-list">
            <ul className="nav d-flex justify-content-between">
              <li>
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#screenshot_modal"
                  onClick={takeScreenshot}
                >
                  <img src={screenshotIcon} alt="ScreenshotIcon" />
                </button>
              </li>
              {/* <li>
                <button
                  className="btn"
                  onClick={() => {
                    switchCamera();
                  }}
                >
                  <img src={refreshIcon} alt="swicthboxIcon" />
                </button>
              </li> */}
            </ul>
          </div>
          <div className="video-btn-bottom">
            <ul className="nav">
              <li>
                <button className="btn btn-end-call" onClick={endCall}>
                  <img src={endcallIcon} alt="EndcallIcon" />
                </button>
              </li>
              <li>
                <button className="btn" onClick={toggleFullscreen}>
                  <img src={fullscreenIcon} alt="FullscreenIcon" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <button
        id="triggerModalButton"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#screenshotModal"
        style={{ display: 'none' }}
      ></button> */}

      {/* Screenshot Modal */}
      {/* <div
        className="modal fade"
        id="screenshotModal"
        tabIndex={-1}
        aria-labelledby="screenshotModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-header">
                <h5 className="modal-title" id="screenshotModalLabel">
                  Save Screenshot
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButton"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                        {...field}
                      />
                    )}
                  />
                  {errors.description && (
                    <div className="invalid-feedback">
                      {errors.description.message}
                    </div>
                  )}
                </div>
                {screenshot && (
                  <img
                    src={screenshot}
                    alt="Screenshot"
                    className="img-fluid"
                  />
                )}
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      <div
        className="modal custom-modal fade"
        id="screenshot_modal"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content profile-modal-content">
            <div className="modal-body profile-modal-body">
              {/* Display the captured screenshot */}
              {screenshot && (
                <div className="profile-modal-img">
                  <img
                    src={screenshot}
                    className="img-fluid"
                    alt="Screenshot"
                  />
                </div>
              )}

              <div className="profile-modal-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <label>Description</label>
                      </div>
                      <div className="col-md-8">
                        <div className="profile-modal-input">
                          <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  type="text"
                                  placeholder="Description"
                                  className={`form-control ${
                                    errors.description ? 'is-invalid' : ''
                                  }`}
                                  onBlur={() => trigger('description')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('description');
                                  }}
                                  maxLength={100}
                                />
                                {errors.description && (
                                  <small className="text-danger d-inline-flex mt-2">
                                    {errors.description.message}
                                  </small>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-modal-btn">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      ref={closeModel}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinCall;
