import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { useDispatch, useSelector } from 'react-redux';
import { setPrescriptionDrugTableData } from '../../../../../../core/redux/commonSlice';
import { end_points } from '../../../../../../services/end_point/end_points';
import { durationOptions } from '../../../../../../assets/global';
import { capitalizeFirstLetter } from '../../../../../../utils/constant';

const addDrugValidation = yup.object().shape({
  drugType: yup
    .object({
      name: yup.string().trim().required('Drug type is required'),
    })
    .nullable()
    .required('Drug type is required'),
  medicines: yup
    .object({
      name: yup.string().trim().required('Medicines is required'),
    })
    .nullable()
    .required('Medicines is required'),
  dosage: yup.string().trim().required('Dosage is required'),
  frequency: yup
    .object({
      name: yup.string().trim().required('Times per day is required'),
    })
    .nullable()
    .required('Times per day is required'),
  freq: yup
    .object()
    .shape({
      morning: yup.boolean(),
      afternoon: yup.boolean(),
      evening: yup.boolean(),
      night: yup.boolean(),
    })
    .test(
      'at-least-one-selected',
      'You must select at least one time of day',
      (value) =>
        value.morning || value.afternoon || value.evening || value.night,
    ),
  measures: yup
    .object({
      name: yup.string().trim().required('Measures is required'),
    })
    .nullable()
    .required('Measures is required'),
  days: yup
    .object({
      name: yup.string().trim().required('Days is required'),
    })
    .nullable()
    .required('Days is required'),
  route: yup
    .object({
      name: yup.string().trim().required('Route is required'),
    })
    .nullable()
    .required('Route is required'),
  duration: yup.string().trim().required('Duration is required'),
  instruction: yup.string().trim().required('Instruction is required'),
});

const AddDrugModel = ({
  editData = '',
  cancel = () => {},
  setIsDrugHere = () => {},
}) => {
  const drugData = useSelector(
    (state: any) => state.common.prescriptionDrugTableData,
  );

  const {
    handleSubmit,
    control,
    trigger,
    setError,
    register,
    reset,
    setValue,
    watch,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(addDrugValidation),
    defaultValues: {
      drugType: { name: '' },
      measures: null,
      route: null,
      medicines: null,
      dosage: '',
      frequency: { name: '' },
      duration: '',
      instruction: '',
      days: { name: 'Every day', code: 'Every day' },
      freq: {
        morning: true,
        afternoon: false,
        evening: false,
        night: false,
      },
    },
  });
  const closeRef = useRef<any>(null);
  const isFirstMedicineSet = useRef(false);

  const drugOptions = [
    { name: 'Mg', code: 'mg' },
    { name: 'Ml', code: 'ml' },
    { name: 'Count', code: 'count' },
  ];
  const TimesPerDayOptions = [
    { name: '1 time per day', code: '1' },
    { name: '2 times per day', code: '2' },
    { name: '3 times per day', code: '3' },
    { name: '4 times per day', code: '4' },
  ];
  const DaysOptions = [
    { name: 'Every day', code: 'Every day' },
    { name: 'Selected Days', code: 'Selected Days' },
    { name: 'Alternate Days', code: 'Alternate Days' },
    { name: 'SOS', code: 'SOS' },
    { name: 'Only Once', code: 'Only Once' },
    { name: 'Monthly', code: 'Monthly' },
    { name: 'Weekly', code: 'Weekly' },
    { name: 'In two weeks', code: 'In two weeks' },
  ];
  const watchDrug = watch('drugType');
  const dispatch = useDispatch();
  let editDrugTable = useSelector(
    (state: any) => state.common.prescriptionDrugTableData,
  );
  const { getData } = useContext(ApiServiceContext);
  const [drugOptionsList, setDrugOptions] = useState<any>([]);
  const [routeOptions, setRouteOptions] = useState<any>([]);
  const [medicineOptions, setMedicineOptions] = useState<any>([]);
  const [allMedicine, setAllMedicine] = useState<any>([]);
  const [showCheckboxes, setShowCheckboxes] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    const {
      drugType,
      measures,
      days,
      route,
      medicines,
      frequency,
      freq,
      instruction,
      ...rest
    } = data;
    const tableData = {
      drug_type: drugType.name,
      measure: measures.name,
      days: days.name,
      route: route?.name?.toLowerCase(),
      medicine: medicines.name,
      frequency: frequency.name,
      instructions: instruction,
      timings: Object.keys(freq)
        .filter((val: any) => freq[val] === true)
        .map((item: any) => {
          return { name: item };
        }),
      ...rest,
    };
    if (editData !== '') {
      const index: number = Number(editData);
      const updatedDrugTable = [...editDrugTable];
      updatedDrugTable[index] = tableData;

      dispatch(setPrescriptionDrugTableData(updatedDrugTable));
    } else {
      const newData = Array.isArray(drugData)
        ? [...drugData, tableData]
        : [tableData];

      dispatch(setPrescriptionDrugTableData(newData));
    }

    if (closeRef.current) {
      closeRef.current.click();
      clearErrors();
      reset();
      cancel();
      setIsDrugHere(false);

      setShowCheckboxes(false);
    }
  };
  const getMasterList = async () => {
    // fetch master list here
    try {
      const res = await getData(end_points.getmasterListApi.url);
      if (res.status === 200) {
        const option = res?.data?.data?.map((item: any) => {
          return { id: item?.id, name: item?.name, code: item?.id };
        });
        setDrugOptions(option);
      }
      const resRoute = await getData(end_points.getRouteListApi.url);
      if (resRoute.status === 200) {
        const option = resRoute?.data?.data?.map((item: any) => {
          return {
            id: item?.id,
            name: capitalizeFirstLetter(item?.name),
            code: item?.id,
          };
        });

        setRouteOptions(option);
      }
      const medicineRes = await getData(end_points.getMedicinesListApi.url);

      if (medicineRes.status === 200) {
        setAllMedicine(medicineRes?.data?.data);
      }
      // getRouteListApi
      // getMedicinesListApi
    } catch (error) {}
  };
  const freqencyWatch = watch('frequency');
  const freq = watch('freq');

  const getValidCheck = (field: any) => {
    const code = freqencyWatch.code ? parseInt(freqencyWatch.code) : '';
    const valid = Object.keys(freq).filter((key) => freq[key] === true);
    const name = field.name.split('.')[1];
    if (valid.length === code) {
      return valid.includes(name) ? false : true;
    } else {
      if (Number(valid?.length) > Number(code)) {
        const last = valid.pop();
        setValue(`freq.${last}`, false);
        trigger(`freq.${last}`);
      }
      return false;
    }
  };
  useEffect(() => {
    const option =
      allMedicine?.flatMap((item: any) => {
        return item?.name.flatMap((nameObj: any) => {
          return Object.keys(nameObj)
            .filter(
              (key: string) =>
                key.toLowerCase() === watchDrug?.name?.toLowerCase(),
            )
            .flatMap((key: string) => {
              return nameObj[key]?.map((drug: any) => {
                return {
                  id: drug?.name,
                  name: drug?.name,
                  code: drug?.name,
                };
              });
            });
        });
      }) || [];

    setMedicineOptions(option || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDrug]);
  useEffect(() => {
    if (medicineOptions?.length > 0) {
      const dataz = drugData?.find((_: any, index: any) => index === editData);
      const val = medicineOptions.find(
        (item: any) => item.name === dataz?.medicine,
      );
      if (val && !isFirstMedicineSet?.current) {
        setValue('medicines', val ? val : null);
        trigger('medicines');
        isFirstMedicineSet.current = true;
      }
    }
  }, [medicineOptions]);
  useEffect(() => {
    getMasterList();
  }, []);
  useEffect(() => {
    const dataz = drugData?.find((_: any, index: any) => index === editData);
    const editDatas: any = dataz?.frequency ? dataz : {};
    if (Object.keys(editDatas).length > 0) {
      const optionDays = DaysOptions.find(
        (data) => data.name == editDatas?.days,
      );
      const optionMeasures = drugOptions.find(
        (data) => data.name === editDatas?.measure,
      );
      const optionRoute = routeOptions?.find(
        (data: any) =>
          data.name.toLowerCase() === editDatas?.route?.toLowerCase(),
      );
      const optionDrugType = drugOptionsList.find(
        (data: any) => data.name === editDatas?.drug_type,
      );
      const optionFrequencyType = TimesPerDayOptions.find(
        (data: any) => data.name === editDatas?.frequency,
      );
      const opt = editDatas?.timings?.map((val: any) => val?.name);
      const freqObj = {
        morning: opt?.includes('morning') || false,
        afternoon: opt?.includes('afternoon') || false,
        evening: opt?.includes('evening') || false,
        night: opt?.includes('night') || false,
      };
      setValue('drugType', optionDrugType);
      setValue('measures', optionMeasures);
      setValue('days', optionDays);
      setValue('route', optionRoute);
      setValue('dosage', editDatas.dosage);
      setValue('frequency', optionFrequencyType);
      setValue('duration', editDatas.duration);
      setValue('instruction', editDatas.instructions);
      setValue('freq', freqObj);
      setShowCheckboxes(true);
    } else {
      reset();
      setShowCheckboxes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);
  return (
    <div
      className="modal custom-modal fade"
      id="add_drug"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="medical-modal-header">
            <button
              type="button"
              className="close"
              ref={closeRef}
              data-bs-dismiss="modal"
              onClick={() => {
                reset();
                cancel();
                clearErrors();
                isFirstMedicineSet.current = false;
              }}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body drug-modal-body">
            <form id="addDrugForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="patient-register-input">
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="form-wrap">
                      <Controller
                        name="drugType"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('drugType')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('drugType');
                                setValue('medicines', null);
                              }}
                              options={drugOptionsList}
                              optionLabel="name"
                              placeholder="Drug Type"
                              className={`w-100 patient-reg-select ${errors.drugType ? 'is-invalid' : ''}`}
                            />
                            {errors.drugType && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.drugType.name?.message ||
                                  errors.drugType.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-wrap">
                      <Controller
                        name="medicines"
                        control={control}
                        render={({ field }) => (
                          <>
                            {/* <input
                              {...field}
                              type="text"
                              placeholder="Medicines"
                              className={`form-control ${
                                errors.medicines ? 'is-invalid' : ''
                              }`}
                              onBlur={() => trigger('medicines')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('medicines');
                              }}
                            /> */}
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('medicines')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('medicines');
                              }}
                              // filter={true}
                              options={medicineOptions}
                              optionLabel="name"
                              placeholder="Add medicine"
                              className={`w-100 patient-reg-select ${errors.medicines?.name ? 'is-invalid' : ''}`}
                            />
                            {errors.medicines && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.medicines.name?.message ||
                                  errors.medicines.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-wrap drug-form-wrap">
                      <div className="row">
                        <div className="col">
                          <Controller
                            name="dosage"
                            control={control}
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  type="text"
                                  placeholder="Dosage"
                                  className={`form-control ${
                                    errors.dosage ? 'is-invalid' : ''
                                  }`}
                                  onBlur={() => trigger('dosage')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('dosage');
                                  }}
                                />
                                {errors.dosage && (
                                  <small className="text-danger d-inline-flex mt-2">
                                    {errors.dosage.message}
                                  </small>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col">
                          <div className="drug-select">
                            <Controller
                              name="measures"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Dropdown
                                    {...field}
                                    value={field.value}
                                    onBlur={() => trigger('measures')}
                                    onChange={(event: any) => {
                                      field.onChange(event);
                                      trigger('measures');
                                    }}
                                    options={drugOptions}
                                    optionLabel="name"
                                    placeholder="Measures"
                                    className={`w-100 patient-reg-select ${errors.measures ? 'is-invalid' : ''}`}
                                  />
                                  {errors.measures && (
                                    <small className="text-danger d-inline-flex mt-2">
                                      {errors.measures.name?.message ||
                                        errors.measures.message}
                                    </small>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    {/* <div className="form-wrap">
                      <Controller
                        name="frequency"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('frequency')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('frequency');
                              }}
                              options={TimesPerDayOptions}
                              optionLabel="name"
                              placeholder="Select times(per day)"
                              className={`w-100 patient-reg-select ${errors?.frequency?.name ? 'is-invalid' : ''}`}
                            />
                            {errors?.frequency && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors?.frequency?.name?.message ||
                                  errors.frequency.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div> */}
                    <div className="form-wrap">
                      {/* Frequency Dropdown */}
                      <Controller
                        name="frequency"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('frequency')}
                              onChange={(event) => {
                                field.onChange(event);
                                trigger('frequency');
                                // Show checkboxes if any option is selected
                                setShowCheckboxes(event.value?.name !== '');
                              }}
                              options={TimesPerDayOptions}
                              optionLabel="name"
                              placeholder="Times(per day)"
                              className={`w-100 patient-reg-select ${errors?.frequency?.name ? 'is-invalid' : ''}`}
                            />
                            {errors?.frequency && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors?.frequency?.name?.message ||
                                  errors.frequency.message}
                              </small>
                            )}
                          </>
                        )}
                      />

                      {/* Conditional Checkboxes */}
                      {showCheckboxes && (
                        <div className="d-flex justify-content-between mt-3 checkduration">
                          <div className="form-check">
                            <Controller
                              name="freq.morning"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <label
                                    className="form-check-label"
                                    htmlFor="morning"
                                  >
                                    Morning
                                  </label>
                                  <input
                                    type="checkbox"
                                    id="morning"
                                    className={`form-check-input ${errors.freq ? 'is-invalid' : ''}`}
                                    {...field}
                                    onBlur={() => trigger('freq.morning')}
                                    disabled={getValidCheck(field)}
                                    checked={field.value}
                                    onChange={() => {
                                      field.onChange(!field.value);
                                      trigger('freq.morning');
                                      trigger('freq');
                                    }}
                                  />
                                </>
                              )}
                            />
                          </div>
                          <div className="form-check">
                            <Controller
                              name="freq.afternoon"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <label
                                    className="form-check-label"
                                    htmlFor="afternoon"
                                  >
                                    Afternoon
                                  </label>
                                  <input
                                    type="checkbox"
                                    id="afternoon"
                                    className={`form-check-input ${errors.freq ? 'is-invalid' : ''}`}
                                    {...field}
                                    disabled={getValidCheck(field)}
                                    checked={field.value}
                                    onBlur={() => trigger('freq.afternoon')}
                                    onChange={() => {
                                      field.onChange(!field.value);
                                      trigger('freq.afternoon');
                                      trigger('freq');
                                    }}
                                  />
                                </>
                              )}
                            />
                          </div>
                          <div className="form-check">
                            <Controller
                              name="freq.evening"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <label
                                    className="form-check-label"
                                    htmlFor="evening"
                                  >
                                    Evening
                                  </label>
                                  <input
                                    type="checkbox"
                                    id="evening"
                                    className={`form-check-input ${errors.freq ? 'is-invalid' : ''}`}
                                    {...field}
                                    checked={field.value}
                                    disabled={getValidCheck(field)}
                                    onBlur={() => trigger('freq.evening')}
                                    onChange={() => {
                                      field.onChange(!field.value);
                                      trigger('freq.evening');
                                      trigger('freq');
                                    }}
                                  />
                                </>
                              )}
                            />
                          </div>
                          <div className="form-check">
                            <Controller
                              name="freq.night"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <label
                                    className="form-check-label"
                                    htmlFor="night"
                                  >
                                    Night
                                  </label>
                                  <input
                                    type="checkbox"
                                    id="night"
                                    className={`form-check-input ${errors.freq ? 'is-invalid' : ''}`}
                                    {...field}
                                    checked={field.value}
                                    onBlur={() => trigger('freq.night')}
                                    disabled={getValidCheck(field)}
                                    onChange={() => {
                                      field.onChange(!field.value);
                                      trigger('freq.night');
                                      trigger('freq');
                                    }}
                                  />
                                </>
                              )}
                            />
                          </div>
                        </div>
                      )}

                      {errors?.freq && (
                        <small className="text-danger d-inline-flex mt-2">
                          {errors?.freq?.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-wrap">
                      <Controller
                        name="days"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('days')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('days');
                              }}
                              options={DaysOptions}
                              optionLabel="name"
                              placeholder="Select days"
                              className={`w-100 patient-reg-select ${errors.days ? 'is-invalid' : ''}`}
                            />
                            {errors.days && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.days.name?.message ||
                                  errors.days.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-wrap">
                      <Controller
                        name="route"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('route')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('route');
                              }}
                              options={routeOptions}
                              optionLabel="name"
                              placeholder="Route"
                              className={`w-100 patient-reg-select ${errors.route ? 'is-invalid' : ''}`}
                            />
                            {errors.route && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.route.name?.message ||
                                  errors.route.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-wrap">
                      <Controller
                        name="duration"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              type="text"
                              placeholder="Duration(No. of days)"
                              className={`form-control ${
                                errors.duration ? 'is-invalid' : ''
                              }`}
                              onBlur={() => trigger('duration')}
                              onChange={(event: any) => {
                                let value: any = event.target.value;
                                if (/^\d+$/.test(value)) {
                                  value += ' Days';
                                } else {
                                  value = value;
                                }
                                field.onChange(value);
                                trigger('duration');
                              }}
                            />
                            {errors.duration && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.duration.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-wrap">
                      <Controller
                        name="instruction"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              type="text"
                              placeholder="Add Instruction"
                              className={`form-control ${
                                errors.instruction ? 'is-invalid' : ''
                              }`}
                              onBlur={() => trigger('instruction')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('instructions');
                              }}
                            />
                            {errors.instruction && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.instruction.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="drug-btn">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    form="addDrugForm"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDrugModel;
