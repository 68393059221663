import React from 'react';
import {
  differenceInYears,
  differenceInDays,
  addYears,
  format,
} from 'date-fns';
import moment from 'moment';

export const capitalizeFirstLetter = (str: string) => {
  if (typeof str === 'string') {
    return str
      ? str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
      : str;
  } else return str;
};

export const validatePhoneNumberwithPlus = (value: string) => {
  if (!value.startsWith('+')) {
    value = `+${value}`;
  } else value = `${value}`;
  return value;
};
export const viewTypes: string[] = ['All', 'Active', 'Inactive'];

export const maskMobileNumber = (number: any) => {
  const str = number?.toString();
  const lastThreeDigits = str?.slice(-3);
  const maskedNumber = `*******${lastThreeDigits}`;
  return maskedNumber;
};

export const convertAvailableDoctorToOptions = (data: any, type = '') => {
  return (
    data?.map((item: any) => ({
      id: type === 'patient' ? item?.patient_id : item?.id,
      name: item?.fullname,
      code: type === 'patient' ? item?.patient_id : item?.id,
    })) || []
  );
};
export const roundToFixed = (value: any) =>
  value ? parseFloat(value).toFixed(2) : value;
export const patientScheduleSlotFormat = (
  appointmentDate: any,
  timeSlot: any = ' - ',
) => {
  const startTime = timeSlot.split('-')[0];
  const today = moment(appointmentDate).format('YYYY-MM-DD');
  const dateTimeString = `${today} ${startTime}`;
  const dateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm');

  const formattedDateTime = dateTime.format('MMMM D, hh:mm A');

  return formattedDateTime;
};

export const dateFormat_YYYY_MM_DD = (date: any) =>
  date ? moment(date).format('YYYY-MM-DD') : date;
export const dateFormat_DD_MM_YYYY = (date: any) =>
  moment(date).format('DD-MM-YYYY');

export const dateTimeFormat_HH_mm_ss_24Hr = (date: any) => {
  const momentDate = moment(date);
  const timeString = momentDate.format('HH:mm:ss');
  return timeString;
};
export const convertDate = (dateStr: any) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const [year, month, day] = dateStr.split('-');
  return `${parseInt(day)} ${months[parseInt(month) - 1]}, ${year}`;
  // Output: "31 July, 2024"
};

export const formatTime = (time: any) => {
  let [hours, minutes] = time.split(':');
  let period = 'am';

  hours = parseInt(hours, 10);

  if (hours >= 12) {
    period = 'pm';
    if (hours > 12) {
      hours -= 12;
    }
  }

  if (hours === 0) {
    hours = 12;
  }

  return `${hours}.${minutes} ${period}`;
  // Output: "9.00 am - 6.00 pm"
};

export function capitalizeWords(str: string = '') {
  if (typeof str === 'string') {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  } else return str;
}
export const formatAppointmentStart = (appointmentStart: string): string => {
  return format(new Date(appointmentStart), 'MMMM d, h:mm a');
};

export const calculateAge = (dob: string): string => {
  const dateOfBirth = new Date(dob);
  const currentDate = new Date();

  const years = differenceInYears(currentDate, dateOfBirth);

  const nextBirthday = addYears(dateOfBirth, years);

  const days = differenceInDays(currentDate, nextBirthday);

  return `${years} years ${days} days`;
};
