import React, { useEffect, useRef, useState } from 'react';

export const PulseGraph = ({ audioArray = [] }) => {
  const [amplitudeData, setAmplitudeData] = useState<any>([]);

  const canvasRef = useRef<any>(null);

  useEffect(() => {
    if (audioArray && audioArray.length > 0) {
      const amplitudes = audioArray;
      setAmplitudeData((prevData: any) =>
        [...prevData, ...amplitudes].slice(-500),
      );
    }
  }, [audioArray]);

  useEffect(() => {
    if (canvasRef.current && amplitudeData.length > 0) {
      const ctx: any = canvasRef.current.getContext('2d');
      const width = canvasRef.current.width;
      const height = canvasRef.current.height;
      const step = Math.ceil(amplitudeData.length / width);

      ctx.clearRect(0, 0, width, height);

      ctx.fillStyle = '#dcdcdc';
      // '#1a1a1a'; // Dark gray background
      ctx.fillRect(0, 0, width, height);

      ctx.strokeStyle = '#444'; // Subtle grid line color
      ctx.lineWidth = 0.3;

      // Vertical lines (grid)
      for (let i = 0; i < width; i += 10) {
        // Increased density of vertical lines
        ctx.beginPath();
        ctx.moveTo(i, 0);
        ctx.lineTo(i, height);
        ctx.stroke();
      }

      // Horizontal lines (grid)
      for (let i = 0; i < height; i += 10) {
        // Increased density of horizontal lines
        ctx.beginPath();
        ctx.moveTo(0, i);
        ctx.lineTo(width, i);
        ctx.stroke();
      }

      // Draw the waveform on top of the grid
      ctx.beginPath();
      ctx.moveTo(0, height / 2);

      for (let i = 0; i < width; i++) {
        const sampleIndex = i * step;
        if (sampleIndex < amplitudeData.length) {
          const amplitude = amplitudeData[sampleIndex];
          const y = height / 2 - (amplitude * height) / 2; // Map amplitude to Y coordinate
          ctx.lineTo(i, y);
        }
      }

      ctx.strokeStyle = '#0000FF'; // Set waveform color to green
      ctx.lineWidth = 1.5; // Make the waveform line slightly thicker
      ctx.stroke();
    }
  }, [amplitudeData]);

  return (
    <canvas
      ref={canvasRef}
      width={500}
      height={100}
      style={{ border: '1px solid #000' }}
    />
  );
};
