// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import io from 'socket.io-client';
// import { favicon } from '../../utils/imagepath';
// import {
//   setAppoinmentStatusUpdate,
//   setAvailableDoctorUpdate,
//   setEditData,
//   setScheduleDoctorUpdate,
//   setSessionDetails,
//   setVitalsUpdated,
// } from '../../core/redux/commonSlice';
// import { toast } from 'react-toastify';

// const SOCKET_SERVER_URL = 'wss://dev-cc-cron.dreamstechnologies.com';
// //  'wss://dev-cc-apigateway.dreamstechnologies.com';

// const SocketClient = () => {
//   const loginUser = useSelector((state: any) => state?.login?.userDetails);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (loginUser && Object.keys(loginUser).length > 0) {
//       console.log('loginUser', loginUser);

//       console.log('Initial Notification Permission:', Notification.permission);

//       if (Notification.permission !== 'granted') {
//         Notification.requestPermission()
//           .then((permission) => {
//             console.log('Requested Notification Permission:', permission);
//             if (permission !== 'granted') {
//               console.log('Notification permission denied');
//             }
//           })
//           .catch((error) => {
//             console.error('Error requesting notification permission:', error);
//           });
//       } else {
//         console.log('Notification permission already granted');
//       }

//       const socket = io(SOCKET_SERVER_URL, {
//         transports: ['websocket'],
//         withCredentials: true,
//       });

//       socket.on('connect_error', (err: any) => {
//         console.log(err);
//         console.log(err?.message);
//         console.log(err?.description);
//         console.log(err?.context);
//       });

//       socket.on('connect', () => {
//         console.log('Connected to socket server');
//       });

//       socket.on('connect_error', (error) => {
//         console.error('Connection Error:', error);
//       });

//       socket.on('connect_failed', () => {
//         console.error('Connection Failed');
//       });
// socket.on('CC_notify', (data) => {
//   console.log(data, 'CC_notify');
// });
//       socket.on(`${loginUser?.id}_notification`, (dat) => {
//         const data = dat ? JSON.parse(JSON.stringify(dat)) : dat;

//         console.log('Received notification data:', data);
//         if (data.type === 'Notification') {
//           console.log('Notification', data.type);
//           if (Notification.permission === 'granted') {
//             new Notification('AXON', {
//               body: data.status,
//               icon: favicon,
//             });
//           } else {
//             console.log('Notification permission not granted');
//           }
//         } else if (data.type === 'Appointment Status Update') {
//           console.log('Appointment Status notification', data.type);
//           dispatch(setAppoinmentStatusUpdate(dat));
//         } else if (data.type === 'Availability doctor') {
//           dispatch(setAvailableDoctorUpdate(data.status));
//         } else if (data.type === 'start-call') {
//           dispatch(setEditData(data.status));
//           dispatch(setSessionDetails(data.status));
//         } else if (data.type === 'vitals') {
//           dispatch(setVitalsUpdated(data.status));
//         } else if (data.type === 'call-end') {
//           toast.error(data.status);
//         } else if (data.type === 'Scheduled Appointment') {
//           const dat = data.status
//             ? JSON.parse(JSON.stringify(data.status))
//             : data.status;
//           dispatch(setScheduleDoctorUpdate(dat));
//         }
//         // else if (data.type === 'offline-end-call') {
//         //   dispatch(setEditData(data.status));
//         //   dispatch(setSessionDetails(data.status));
//         // }
//       });

//       return () => {
//         socket.disconnect();
//       };
//     }
//   }, [loginUser]);

//   return null;
// };

// export default SocketClient;
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { favicon } from '../../utils/imagepath';
import {
  setAppoinmentStatusUpdate,
  setAvailableDoctorUpdate,
  setEditData,
  setScheduleDoctorUpdate,
  setSessionDetails,
  setVitalsUpdated,
} from '../../core/redux/commonSlice';
import { toast } from 'react-toastify';

export const SOCKET_SERVER_URL_1 =
  'wss://dev-cc-apigateway.dreamstechnologies.com';
export const SOCKET_SERVER_URL_HTTPS_1 =
  'https://dev-cc-apigateway.dreamstechnologies.com';
const SOCKET_SERVER_URL_2 = 'wss://dev-cc-cron.dreamstechnologies.com'; // Replace with your second server URL
interface socketProps {
  children: any;
}

const initialData: any = {
  handleSocketEvents: () => {},
  socketOne: null,
  socketTwo: null,
};
export const SocketContext = createContext<any>(initialData);
const SocketClient: React.FC<socketProps> = ({ children }) => {
  const loginUser = useSelector((state: any) => state?.login?.userDetails);
  const dispatch = useDispatch();
  const [socketOne, setSocketOne] = useState<any>(null);
  const [socketTwo, setSocketTwo] = useState<any>(null);
  const handleSocketEvents = (
    socketType = '',
    listener = '',
    type = '',
    SuccessCallBack = (data: any) => {},
  ) => {
    let socket = type === 'Cron' ? socketTwo : socketOne;

    if (socketOne || socketTwo) {
      socket.on(`${loginUser?.id}_notification`, (data: any) => {
        console.log(
          `Received notification data from [${socket.io.uri}]:`,
          data,
        );
        const dataz = data ? JSON.parse(JSON.stringify(data)) : data;
        handleNotification(dataz);
      });
      socket.on('CC_notify', (data: any) => {
        console.log(data, 'CC_notify');
      });
    }

    if (socketType === 'vitalsCall' && listener && socketOne) {
      let dataValue;
      socket.on(listener, (data: any) => {
        console.log(
          `Received notification data from [${socket.io.uri}]:`,
          data,
        );
        dataValue = data ? JSON.parse(JSON.stringify(data)) : data;
        SuccessCallBack(dataValue);
        // dispatch(setVitalsUpdated(dataValue));
      });
      // let appointmentId = '';
      // socket.on(`cc_appointment_${appointmentId}`, (data: any) => {
      //   console.log(`Received notification data from [${socket.io.uri}]:`, data);
      //   console.log(`cc_appointment_${appointmentId}`, 'appointmentId after');

      //   const dataValue = data ? JSON.parse(JSON.stringify(data)) : data;
      //   handleNotification(dataValue);
      // });
      return dataValue;
    }
  };
  const handleNotification = (data: any) => {
    switch (data.type) {
      case 'Notification':
        if (Notification.permission === 'granted') {
          new Notification('AXON', {
            body: data.status,
            icon: favicon,
          });
        }
        break;
      case 'Appointment Status Update':
        dispatch(setAppoinmentStatusUpdate(data));
        break;
      case 'Availability doctor':
        dispatch(setAvailableDoctorUpdate(data.status));
        break;
      case 'start-call':
        console.log('start-call:data::', data.status);
        dispatch(setEditData(data.status));
        dispatch(setSessionDetails(data.status));
        break;

      // case 'CC_notify':
      //   console.log(data, 'CC_notify');
      //   break;
      case 'call-end':
        toast.error(data.status);
        break;
      case 'Scheduled Appointment':
        dispatch(
          setScheduleDoctorUpdate(
            data.status ? JSON.parse(JSON.stringify(data.status)) : data.status,
          ),
        );
        break;
      default:
        console.log('Unknown notification type:', data.type);
    }
  };
  useEffect(() => {
    if (loginUser && Object.keys(loginUser).length > 0) {
      // Request Notification permission if not already granted
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().catch((error) =>
          console.error('Error requesting notification permission:', error),
        );
      }

      // Initialize socket connections
      const socket1 = io(SOCKET_SERVER_URL_1, {
        transports: ['websocket'],
        withCredentials: true,
      });
      setSocketOne(socket1);
      const socket2 = io(SOCKET_SERVER_URL_2, {
        transports: ['websocket'],
        withCredentials: true,
      });
      setSocketTwo(socket2);

      // Clean up sockets on component unmount
      return () => {
        socket1.disconnect();
        socket2.disconnect();
      };
    }
  }, [loginUser]);
  useEffect(() => {
    if (socketOne) handleSocketEvents();

    if (socketTwo) handleSocketEvents('Cron');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketOne, socketTwo]);

  return (
    <>
      <SocketContext.Provider
        value={{
          handleSocketEvents: handleSocketEvents,
          socket: socketOne,
          socketCron: socketTwo,
        }}
      >
        {children}
      </SocketContext.Provider>
    </>
  );
};

export default SocketClient;
