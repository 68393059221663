import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  dash01,
  dash02,
  dash03,
  dash04,
  dash05,
} from '../../../../utils/imagepath';
import { routes } from '../../../../utils/routes';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';

const Dashboard = () => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [listData, setListData] = useState<any>([]);
  const [redFlag, setredFlag] = useState<any>([]);
  const [flagCount, setFlagCount] = useState<any>([]);

  const getDashboardData = async () => {
    try {
      showLoader();
      let url = end_points.adminDashboard.url;
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response.data.data);
      }
      const redFlasgs = await getData(end_points.redFlagApi.url);

      if (redFlasgs.status === 200) {
        const count = redFlasgs?.data?.data?.filter(
          (d: any) => d?.pastCount || d?.todayCount,
        );
        setredFlag(redFlasgs.data.data);
        setFlagCount(count || []);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Dashboard */}
          <div className="row">
            <div className="col-lg-3 col-md-12 theiaStickySidebar">
              <div className="stickybar">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="dash-header">
                      <h4>Active Status</h4>
                    </div>
                    <div className="dash-progress">
                      <p>Doctors</p>
                      <h6>{listData?.doctor_active_count || 0}</h6>
                      <div className="progress-info">
                        <div className="progress">
                          <div
                            className="progress-bar bg-primary"
                            style={{
                              width: `${(listData?.doctor_active_count / listData?.doctor_total_count) * 100}%`,
                            }}
                          />
                        </div>
                        <span>{listData?.doctor_total_count || 0}</span>
                      </div>
                    </div>
                    <div className="dash-progress">
                      <p>Kiosk</p>
                      <h6>{listData?.kiosk_active_count || 0}</h6>
                      <div className="progress-info">
                        <div className="progress">
                          <div
                            className="progress-bar bg-primary"
                            style={{
                              width: `${(listData?.kiosk_active_count / listData?.kiosk_total_count) * 100}%`,
                            }}
                          />
                        </div>
                        <span>{listData?.kiosk_total_count || 0}</span>
                      </div>
                    </div>
                    <div className="dash-progress">
                      <p>Paramedic</p>
                      <h6>{listData?.paramedic_active_count || 0}</h6>
                      <div className="progress-info">
                        <div className="progress">
                          <div
                            className="progress-bar bg-primary"
                            style={{
                              width: `${(listData?.paramedic_active_count / listData?.paramedic_total_count) * 100}%`,
                            }}
                          />
                        </div>
                        <span>{listData?.paramedic_total_count || 0}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-header">
                      <h4>Red Flags</h4>
                    </div>
                    <div className="red-flag-list">
                      {flagCount && flagCount?.length > 0 ? (
                        <ul className="nav">
                          {redFlag?.map((item: any) => {
                            let text = '';

                            if (item?.pastCount > 0 && item.todayCount > 0) {
                              text = `${item?.pastCount} ${
                                item?.status
                              }  from yesterday and ${item?.todayCount} for today`;
                            } else if (item?.pastCount > 0) {
                              text = `${item?.pastCount} ${item?.status === 'waiting' ? 'Patients' : 'Appointments'} ${
                                item?.status
                              }   ${item?.status !== 'completed' && item?.status !== 'cancelled' ? 'from yesterday' : ''}`;
                            } else if (item?.todayCount > 0) {
                              text = `${item?.todayCount} ${
                                item?.status
                              } for today`;
                            } else {
                              return null;
                              text = `No ${item?.status}`;
                            }
                            return (
                              <li>
                                <span /> <p>{text}</p>
                              </li>
                            );
                          })}
                          {/* {<li>
                          <span /> <p>7 Appointments pending from yesterday</p>
                        </li>
                        <li>
                          <span /> <p>3 Patients waiting for a follow-up</p>
                        </li>
                        <li>
                          <span /> <p>3 Patients waiting for a follow-up</p>
                        </li>} */}
                        </ul>
                      ) : (
                        <ul>
                          <li>No flags</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 d-flex">
              <div className="card w-100">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-sm-4">
                      <div className="dashboard-link">
                        <Link to={routes.kioskList.path}>
                          <img src={dash01} alt="Icon" />
                          Kiosk
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="dashboard-link">
                        <Link to={routes.paramedicList.path}>
                          <img src={dash02} alt="Icon" />
                          Paramedic
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="dashboard-link">
                        <Link to={routes.doctorsList.path}>
                          <img src={dash03} alt="Icon" />
                          Doctors
                        </Link>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="dashboard-link">
                        <Link to={routes.frontDeskList.path}>
                          <img src={dash04} alt="Icon" />
                          Front Desk
                        </Link>
                      </div>
                    </div>
                    {/* <div className="col-sm-4">
                      <div className="dashboard-link">
                        <Link to="#">
                          <img src={dash05} alt="Icon" />
                          Patients
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Dashboard;
