import React, { useContext, useEffect, useRef, useState } from 'react';
import { defaultProfileTwoImage, readIcon } from '../../../utils/imagepath';
import { end_points } from '../../../services/end_point/end_points';
import { SpinnerContext } from '../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../utils/shared.module';
import { formatDistanceToNow } from 'date-fns';
import { toast } from 'react-toastify';
import { debounce } from '../../../utils/debounce';
import { useDispatch } from 'react-redux';
import { setNotifyCountBool } from '../../../core/redux/commonSlice';

const NotificationList = () => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, putData, deleteData } = useContext(ApiServiceContext);
  const [notificationListData, setNotificationListData] = useState<any>([]);
  const [notificationCount, setNotificationCount] = useState<any>({});
  const [selectedNotifications, setSelectedNotifications] = useState<any>([]);
  const dispatch = useDispatch();
  const scrollRef = useRef<any>(null);

  const [displayedItems, setDisplayedItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  // const [scrollViewHeight, setscrollViewHeight] = useState(window.innerHeight);
  const headerHeight: any =
    document.querySelector('.header')?.clientHeight || 100;
  const notificationHeaderHeight: any =
    document.querySelector('.notification-header')?.clientHeight || 104;
  const notificationReadHeight: any =
    document.querySelector('.notification-read')?.clientHeight || 0;

  const profilebreadcrumbHeight: any =
    document.querySelector('.profile-breadcrumb')?.clientHeight || 104;

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemHeight = 90;
  const scrollHeight =
    viewHeight -
      (headerHeight + notificationHeaderHeight + notificationReadHeight + 45) ||
    500;

  const handleResize = () => {
    // console.count('resize');
    const newViewHeight = window.innerHeight;
    setViewHeight(newViewHeight);

    // const scrollableElement = document.getElementById('scrollabeldiv');
    // if (scrollableElement) {
    //   scrollableElement.style.height = `${desiredScrollHeight - 50}px`;
    // }
  };
  // need to check
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // // console.log(viewHeight, itemsPerPage, 'view height and items per page');
  // useEffect(() => {
  //   // // console.log(scrollHeight, 'scrollHeight');

  //   const newItemsPerPage = Math.floor(scrollHeight / itemHeight);
  //   // // console.log(newItemsPerPage, 'newItemsPerPage');

  //   setItemsPerPage(newItemsPerPage > 10 ? newItemsPerPage : 10);
  // }, [scrollHeight]);
  const loadMoreItems = () => {
    if (loading) {
      return;
    }
    if (notificationListData.length < itemsPerPage) {
      setDisplayedItems(notificationListData);
      return;
    }
    if (
      notificationListData.length > itemsPerPage &&
      notificationListData.length === displayedItems.length
    ) {
      return;
    }

    setLoading(true);

    const newItems = notificationListData?.slice(
      displayedItems.length,
      displayedItems.length + itemsPerPage,
    );

    setDisplayedItems((prev: any) => [...prev, ...newItems]);
    setLoading(false);
  };
  const debouncedLoadMoreItems = debounce(loadMoreItems, 300);
  const handleScroll = () => {
    if (!scrollRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 100) {
      debouncedLoadMoreItems();
    }
  };
  const handleSelectNotification = (notificationId: any) => {
    if (notificationId === 'ALL') {
      setSelectedNotifications(
        notificationListData?.map((item: any) => item?._id),
      );

      return;
    }
    if (notificationId === 'CLEAR') {
      setSelectedNotifications([]);
      return;
    }
    setSelectedNotifications((prevSelected: any) =>
      prevSelected.includes(notificationId)
        ? prevSelected.filter((_id: any) => _id !== notificationId)
        : [...prevSelected, notificationId],
    );
  };

  const handleMarkAsRead = () => {
    // console.log('Marking as read:', selectedNotifications);
    if (selectedNotifications.length === 0) {
      toast.info('Kindly select notifications to mark it as read');
      return;
    }
    markRead();
  };

  const handleClearAll = () => {
    // console.log('Clearing all notifications', selectedNotifications);
    if (selectedNotifications.length === 0) {
      toast.info('Kindly select notifications to clear');
      return;
    }
    deleteNotification();
  };

  const markRead = async (ids_new: any = selectedNotifications) => {
    const id_status = Array.isArray(ids_new);
    // // console.log(id_status, 'id_status');

    try {
      id_status && showLoader();
      let url = end_points.notificationStatus.url;
      const payload = {
        ids: id_status ? ids_new : [ids_new],
      };
      if (!id_status) {
        setNotificationListData((prev: any) => {
          return prev?.map((item: any) => {
            return item?._id === ids_new ? { ...item, is_readed: true } : item;
          });
        });
        setNotificationCount((prev: any) => {
          return {
            ...prev,
            unreadCount: prev?.unreadCount > 0 ? prev?.unreadCount - 1 : 0,
          };
        });
      }
      const response = await putData(url, payload);
      if (response?.status === 200) {
        if (id_status && ids_new) {
          setSelectedNotifications([]);
          getNotificationList();
          dispatch(setNotifyCountBool('ALL'));
        }
      }
    } catch (error: any) {
    } finally {
      id_status && hideLoader();
    }
  };

  const deleteNotification = async () => {
    let urls = end_points.notificationDelete.url;
    const payload = {
      ids: selectedNotifications,
    };
    const response = await deleteData(urls, payload);
    if (response?.status == 200) {
      getNotificationList();
    }
  };

  const getNotificationList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getNotification.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        // // console.log('response.data', response.data);
        setNotificationListData(response.data.data);
        setNotificationCount(response.data);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getNotificationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   // console.log(notificationListData, 'notificationListData');

  //   if (notificationListData?.length > 0) loadMoreItems();

  //   return () => {
  //     setDisplayedItems([]);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [notificationListData, itemsPerPage]);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid pb-0">
        <div className="row ">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-body pb-0">
                <div className="notification-header">
                  <h4>Notification Details</h4>
                  <ul className="nav">
                    <li>
                      <a href="#" className="active">
                        Unreaded <span>{notificationCount?.unreadCount}</span>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">History</a>
                    </li> */}
                  </ul>
                </div>
                {notificationListData?.length > 0 ? (
                  <>
                    <div className="notification-read">
                      <div className="notification-select-all">
                        <div className="custom-checkbox">
                          <label className="check">
                            <input
                              type="checkbox"
                              checked={
                                selectedNotifications?.length ===
                                notificationListData?.length
                              }
                              onClick={(event: any) => {
                                if (event.target.checked) {
                                  handleSelectNotification('ALL');
                                } else handleSelectNotification('CLEAR');
                              }}
                            />
                            <span className="box"></span> Select all
                          </label>
                        </div>
                      </div>
                      <ul className="nav">
                        <li>
                          <a href="#" onClick={handleMarkAsRead}>
                            <img src={readIcon} alt="Icon" /> Mark as read
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="clear-all-link"
                            onClick={handleClearAll}
                          >
                            <i className="feather icon-trash-2"></i> Clear
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      ref={scrollRef}
                      id="scrollabeldiv"
                      // onScroll={handleScroll}
                      className={
                        notificationListData?.length < 2 ? '' : 'scrollable-div'
                      }
                      style={{ height: scrollHeight }}
                    >
                      {notificationListData?.map((notification: any) => {
                        const timeAgo = formatDistanceToNow(
                          new Date(notification.created_at),
                          { addSuffix: true },
                        );
                        const isSelected = selectedNotifications.includes(
                          notification._id,
                        );

                        return (
                          <div
                            key={notification?._id}
                            className={`notification-grid ${
                              isSelected ? 'selected' : ''
                            } ${notification?.is_readed ? '' : 'notification-grid-unread'}`}
                          >
                            <div className="custom-checkbox notification-details-checkbox">
                              <label className="check">
                                <input
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() => {
                                    handleSelectNotification(notification._id);
                                  }}
                                />
                                <span className="box"></span>
                              </label>
                            </div>
                            <div
                              className="notification-left"
                              onClick={(e: any) => {
                                if (!notification?.is_readed) {
                                  markRead(notification?._id);
                                  let count = notificationCount?.unreadCount
                                    ? notificationCount?.unreadCount - 1
                                    : 0;
                                  dispatch(setNotifyCountBool(count));
                                }
                              }}
                            >
                              <div className="notification-img">
                                <img
                                  src={
                                    notification.senderDetails?.profile_image ||
                                    defaultProfileTwoImage
                                  }
                                  className="img-fluid"
                                  alt="Profile"
                                />
                              </div>
                              <div className="notification-content">
                                <h6>{notification.senderDetails?.fullname}</h6>
                                <p>{notification.description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <p>No records found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationList;
