import React, { useRef } from 'react';

function PacsModelPopup({ srcValue = '' }) {
  const closeRef = useRef(null);
  return (
    <div
      className="modal custom-modal fade"
      id="PacsModelPopupID"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="medical-modal-header prescription-modal-header">
            <h5>Image Preview</h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              ref={closeRef}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body pacs-modal-body">
            <div className="pacs-img">
              {srcValue && <img src={srcValue} alt="I" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PacsModelPopup;
