import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import { Chips } from 'primereact/chips';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'bootstrap';
import { setSymtomsFlowData } from '../../../../../../core/redux/commonSlice';
import { capitalizeFirstLetter } from '../../../../../../utils/constant';

interface SymptomsContext {
  chipValue: string[];
}

const symptomsValidation = yup.object().shape({
  symptoms: yup
    .array()
    .of(yup.string())
    .test(
      'at-least-one-symptom',
      'At least one symptom is required',
      function (value) {
        const context = this.options.context as SymptomsContext;
        const { chipValue } = context;
        return (
          (value && value.length > 0) || (chipValue && chipValue.length > 0)
        );
      },
    ),
  additionSymptoms: yup.string().required('Presenting illness is required'),
  durationNumber: yup
    .object({
      name: yup.string().trim().required('Number is required'),
    })
    .nullable()
    .required('Number is required'),
  durationType: yup
    .object({
      name: yup.string().trim().required('Type is required'),
    })
    .nullable()
    .required('Type is required'),
});

const SymptomsCall = () => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData } = useContext(ApiServiceContext);
  const [chipValue, setChipValue] = useState<any>([]);
  const [filteredSymptoms, setFilteredSymptoms] = useState<any[]>([]);
  const [showAddNote, setShowAddNote] = useState(false);
  const [newSymptom, setNewSymptom] = useState('');
  const [searchSymptom, setSearchSymptom] = useState('');
  const [symptomsListData, setSymptomsListData] = useState<any>({});
  const editData: any = useSelector((state: any) => state?.common?.editData);
  const dispatch = useDispatch();
  const savedData: any = useSelector((state: any) => state?.common?.flowData);
  const sessionDetail = useSelector(
    (state: any) => state?.common?.sessionDetails,
  );
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(symptomsValidation),
    defaultValues: {
      symptoms: [],
      newSymptom: '',
    },
    context: { chipValue },
  });
  const durationOptions = Array.from({ length: 30 }, (_, i) => {
    const value = (i + 1).toString();
    return { name: value, code: value };
  });

  const durationTypeOptions = [
    { name: 'Days', code: 'days' },
    { name: 'Months', code: 'months' },
    { name: 'Year', code: 'year' },
  ];

  const handleAddNote = () => {
    setShowAddNote(true);
  };

  const searchSymptoms = (event: any) => {
    const filtered = symptomsListData.filter((symptom: any) =>
      symptom.name.toLowerCase().startsWith(event.query.toLowerCase()),
    );
    setFilteredSymptoms(
      filtered.length
        ? filtered
        : [{ name: 'No results found', code: '', disabled: true }],
    );
  };

  const handleSaveNewSymptom = () => {
    if (newSymptom && !chipValue.includes(newSymptom)) {
      setChipValue((prev: any) => [...prev, newSymptom]);
      setNewSymptom('');
      setShowAddNote(false);
    }
  };

  const onSubmit = async (data: any) => {
    // if (chipValue.length === 0) {
    //   setError('symptoms', {
    //     type: 'manual',
    //     message: 'At least one symptom is required',
    //   });
    //   return;
    // }
    clearErrors('symptoms');
    // console.log('Form Data:', { ...data, symptoms: chipValue });
    try {
      showLoader();
      let url = end_points.onCallSymptomsTabAdd.url;
      url += `/${editData?.patient_id}`;
      const payload = {
        symptoms: [
          {
            presenting_illness: data.additionSymptoms,
            duration_of_numbers: data.durationNumber.code,
            duration_type: data.durationType.code,
            symptoms:
              chipValue?.length > 0
                ? chipValue?.map((val: any) => {
                    return { name: val };
                  })
                : [],
            symptoms_add_note: newSymptom,
          },
        ],
      };
      const response = await postData(url, payload);
      if (response.status === 200) {
        const newData = {
          ...savedData,
          symptoms: payload,
          joinCallData: { _id: sessionDetail?._id },
        };
        dispatch(setSymtomsFlowData(newData));
        const nextTab = document.querySelector('#vitals-tab') as HTMLElement;
        const nextTabLink = new Tab(nextTab);
        nextTabLink.show();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const newSymptomSubmit = async () => {
    try {
      showLoader();
      const payload = {
        name: newSymptom,
        status: 'active',
      };
      const response = await postData(end_points.newSymptomCreate.url, payload);
      if (response.status === 200) {
        handleSaveNewSymptom();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (chipValue.length > 0) {
      trigger('symptoms');
    }
  }, [chipValue]);
  useEffect(() => {
    // console.log(savedData, 'savedData');

    const data = savedData?.symptoms?.symptoms?.[0];
    if (data) {
      let sss = data?.symptoms?.map((sym: any) => sym.name);
      setChipValue(sss);
      if (data?.symptoms_add_note) {
        setShowAddNote(true);
        setNewSymptom(data?.symptoms_add_note);
      }
      setValue('durationNumber', {
        name: data?.duration_of_numbers,
        code: data?.duration_of_numbers,
      });
      setValue('durationType', {
        name: capitalizeFirstLetter(data?.duration_type),
        code: data?.duration_type,
      });
      setValue('additionSymptoms', data?.presenting_illness);
    }
  }, [savedData?.symptoms]);
  const getSymptomsList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getSymptomsList.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        const symptomsList = response.data.data.map((symptoms: any) => ({
          name: symptoms.name,
          code: symptoms.name,
        }));
        setSymptomsListData(symptomsList);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getSymptomsList();
    const newData = {
      ...savedData,
      joinCallData: {
        _id: sessionDetail?._id,
        paramedic_id: sessionDetail?.paramedic_id,
      },
    };
    dispatch(setSymtomsFlowData(newData));
  }, []);
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key
    }
  };
  return (
    <div
      className="tab-pane fade show active"
      id="symptoms_tab"
      role="tabpanel"
      aria-labelledby="symptoms-tab"
    >
      <form
        id="symptomsForm"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={handleKeyPress}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="symptoms-card">
              <h4>Symptoms</h4>
              <div className="symptoms-search">
                <div className="symptoms-input symptoms-input-control">
                  <Controller
                    name="symptoms"
                    control={control}
                    render={({ field }) => (
                      <AutoComplete
                        value={searchSymptom}
                        suggestions={filteredSymptoms}
                        completeMethod={searchSymptoms}
                        field="name"
                        onBlur={() => trigger('symptoms')}
                        onKeyDown={(e: any) => {
                          if (
                            e.key === 'Enter' &&
                            e.target.value !== 'No results found' &&
                            !chipValue.includes(e.target.value)
                          ) {
                            setChipValue([...chipValue, e.target.value]);
                            setSearchSymptom('');
                          }
                        }}
                        onChange={(e) => {
                          setSearchSymptom(e.value);
                          trigger('symptoms');
                        }}
                        onSelect={(e) => {
                          if (
                            e.value.name !== 'No results found' &&
                            !chipValue.includes(e.value.name)
                          ) {
                            setChipValue([...chipValue, e.value.name]);
                            setSearchSymptom('');
                          }
                        }}
                        itemTemplate={(item) => {
                          if (item.disabled) {
                            return (
                              <span
                                style={{ color: 'gray', pointerEvents: 'none' }}
                              >
                                {item.name}
                              </span>
                            );
                          }
                          return <span>{item.name}</span>;
                        }}
                        placeholder="Search Symptoms"
                        className="form-control"
                      />
                    )}
                  />
                  <i className="feather icon-search"></i>
                </div>
                <div className="symptoms-tags">
                  <ul className="nav">
                    {chipValue.map((symptom: any, index: any) => (
                      <li key={index}>
                        <h6>
                          {symptom}
                          <Link
                            to="#"
                            onClick={() => {
                              setChipValue(
                                chipValue.filter((s: any) => s !== symptom),
                              );
                            }}
                          >
                            <i className="fas fa-xmark" />
                          </Link>
                        </h6>
                      </li>
                    ))}
                  </ul>
                  {errors.symptoms && (
                    <small className="text-danger d-inline-flex mt-2">
                      {errors.symptoms.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="add-symptoms">
                <Link
                  to="#"
                  className="add-note add-note-link"
                  onClick={handleAddNote}
                >
                  Add Note
                </Link>
                {showAddNote && (
                  <div className="add-symptoms-input">
                    <input
                      type="text"
                      value={newSymptom}
                      onChange={(e) => setNewSymptom(e.target.value)}
                      className="form-control"
                      placeholder="Add note"
                    />
                    {/* <Link
                      to="#"
                      className="add-note-link"
                      onClick={newSymptomSubmit}
                    >
                      Save
                    </Link> */}
                  </div>
                )}
              </div>
            </div>
            <div className="symptoms-card">
              <h4>Duration</h4>
              <div className="symptoms-duration">
                <div className="row">
                  <div className="col-lg-5 col-md-12">
                    <div className="symptoms-select">
                      <Controller
                        name="durationNumber"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('durationNumber')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('durationNumber');
                              }}
                              options={durationOptions}
                              optionLabel="name"
                              placeholder="Select Number"
                              className={`w-100 patient-reg-select ${errors.durationNumber ? 'is-invalid' : ''}`}
                            />
                            {errors.durationNumber && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.durationNumber.name?.message ||
                                  errors.durationNumber.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12">
                    <div className="symptoms-select">
                      <Controller
                        name="durationType"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('durationType')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('durationType');
                              }}
                              options={durationTypeOptions}
                              optionLabel="name"
                              placeholder="Select Type"
                              className={`w-100 patient-reg-select ${errors.durationType ? 'is-invalid' : ''}`}
                            />
                            {errors.durationType && (
                              <small className="text-danger d-inline-flex mt-2">
                                {errors.durationType.name?.message ||
                                  errors.durationType.message}
                              </small>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="symptoms-card">
              <h4>Presenting Illness</h4>
              <div className="symptoms-search">
                <div className="symptoms-input">
                  <Controller
                    name="additionSymptoms"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        className={`form-control ${
                          errors.additionSymptoms ? 'is-invalid' : ''
                        }`}
                        value={field.value}
                        onBlur={() => trigger('additionSymptoms')}
                        onChange={(event: any) => {
                          field.onChange(event);
                          trigger('additionSymptoms');
                        }}
                        autoComplete="false"
                        placeholder="Please enter presenting illness."
                        maxLength={100}
                      />
                    )}
                  />
                  <small className="text-danger d-inline-flex mt-2">
                    {errors?.additionSymptoms?.message}
                  </small>
                </div>
              </div>
              <div className="symptoms-save">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  form="symptomsForm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SymptomsCall;
