interface EndPoint {
  url?: string;
  list?: string;
  add?: string;
  edit?: string;
  delete?: string;
  service?: string;
}

export const end_points: Record<string, EndPoint> = {
  //Admin Authentication
  api_endpoint: { url: '', service: 'ms1' }, //  dummy service
  adminLogin: { url: 'tenant/login', service: 'ms1' },
  tenantRegister: { url: 'tenant/create', service: 'ms1' },
  loginOTP: { url: 'tenant/loginOtpGenerate', service: 'ms1' },
  loginOTPVerify: { url: 'tenant/logincheckOtp', service: 'ms1' },
  forgotPasswordOTP: {
    url: 'tenant/forgetPasswordOtpGenerate',
    service: 'ms1',
  },
  forgotPasswordOTPVerify: {
    url: 'tenant/forgetPasswordCheckOtp',
    service: 'ms1',
  },
  updatePassword: {
    url: 'tenant/updatePassword',
    service: 'ms1',
  },

  //User Authentication
  userLogin: { url: 'auth/login', service: 'ms1' },
  userLoginOTP: { url: 'auth/loginOtpGenerate', service: 'ms1' },
  userLoginOTPVerify: { url: 'auth/logincheckOtp', service: 'ms1' },
  userForgotPasswordOTP: {
    url: 'auth/forgetPasswordOtpGenerate',
    service: 'ms1',
  },
  userForgotPasswordOTPVerify: {
    url: 'auth/forgetPasswordCheckOtp',
    service: 'ms1',
  },
  userUpdatePassword: { url: 'auth/updatePassword', service: 'ms1' },

  //getTenant
  getTenant: { url: 'tenant/get-tenant-id', service: 'ms1' },
  //Notification
  getNotification: { url: 'notification/list', service: 'ms1' },
  notificationStatus: { url: 'notification/update-status', service: 'ms1' },
  notificationDelete: { url: 'notification/delete', service: 'ms1' },

  // Master
  degreeListApi: { url: 'master/degree-list', service: 'ms1' },
  getCountryList: { url: 'master/countries-list', service: 'ms1' },
  getGenMedicineList: { url: 'master/general-medicine-list', service: 'ms1' },
  getSymptomsList: { url: 'master/symptoms-list', service: 'ms1' },
  getTestList: { url: 'master/test-list', service: 'ms1' },
  getDiffDiagnosisList: {
    url: 'master/diffrential-diagnosis-list',
    service: 'ms1',
  },
  getDiagnosisList: { url: 'master/diagnosis-list', service: 'ms1' },
  getProvisionalList: {
    url: 'master/professional-diagnosis-list',
    service: 'ms1',
  },
  newSymptomCreate: { url: '/master/create-symptoms', service: 'ms1' },
  newTestCreate: { url: '/master/create-test', service: 'ms1' },
  newDiffDiagnosisCreate: {
    url: 'master/create-diffrential-diagnosis',
    service: 'ms1',
  },
  newDiagnosisCreate: { url: 'master/create-diagnosis', service: 'ms1' },
  newProvisionalCreate: {
    url: 'master/create-professional-diagnosis',
    service: 'ms1',
  },

  // kiosk api endpoint
  adminDashboard: { url: 'dashboard/count', service: 'ms1' },
  // kiosk api endpoint
  kioskListApi: { url: 'kiosk/list', service: 'ms1' },
  kioskCreateApi: { url: 'kiosk/create', service: 'ms1' },
  kioskUpdateApi: { url: 'kiosk/update', service: 'ms1' }, //:id
  kioskgetByIdApi: { url: 'kiosk/list', service: 'ms1' }, //:id
  kioskUpdateStatusApi: { url: 'kiosk/update-status', service: 'ms1' }, //:id
  kioskRemoveAssociateApi: { url: 'kiosk/remove-associate', service: 'ms1' }, //:id
  kioskDeleteApi: { url: 'kiosk/delete', service: 'ms1' }, //:id}
  kioskAssociateFrontdeskUpdateApi: {
    url: 'kiosk-associate/frond-desk/update',
    service: 'ms1',
  }, //:id}
  kioskAssociateParamedicUpdateApi: {
    url: 'kiosk-associate/paramedic/update',
    service: 'ms1',
  }, //:id}
  KioskUserListApi: { url: 'kiosk/kiosk-user-list', service: 'ms1' }, //:id type
  //CRUD Common API's
  adminPagesList: { url: 'kiosk-associate/user-list', service: 'ms1' }, //:id
  adminPagesCreate: { url: 'user/create', service: 'ms1' }, //:id
  adminPagesUpdate: { url: 'user/update', service: 'ms1' }, //:id
  adminPagesDelete: { url: 'user/delete', service: 'ms1' }, //:id
  adminPagesGetById: { url: 'user/details', service: 'ms1' }, //:id
  adminPagesStatusUpdate: { url: 'user/status-update', service: 'ms1' }, //:id

  // associates
  front_paramedicAssociateUpdateApi: {
    url: 'kiosk-associate/update',
    service: 'ms1',
  },
  frontdeskandParamedicAssociateListApi: {
    url: 'kiosk-associate/user-list',
    service: 'ms1',
  }, //:type

  // frontdesk
  frontDeskPatientListApi: {
    url: 'appointment/patient-appointment-list',
    service: 'ms1',
  },
  createAppointmentApi: {
    url: 'appointment/create',
    service: 'ms1',
  },
  rescheduledAppointmentApi: {
    url: 'appointment/reschedule',
    service: 'ms1',
  }, // :id
  frontDeskPatientRegisterApi: {
    url: 'user/create',
    service: 'ms1',
  }, // :id
  frontDeskDoctorAppoinments: {
    url: 'doctor-slot/doctor-slots',
    service: 'ms1',
  }, // :id
  frontDeskDoctorSlotList: {
    url: 'doctor-slot/doctor-slots-list',
    service: 'ms1',
  }, // :id
  getAvailableDoctorsListApi: {
    url: 'user/doctor-available',
    service: 'ms1',
  }, // type:doctor/searchTerm=jai&page=1&limit=10
  getConsultListApi: {
    url: 'user/pasttoday-consults-list',
    service: 'ms1',
  },
  getScheduleSlotbasedonDoctorId: {
    url: 'appointment/slot',
    service: 'ms1',
  }, // ?doctorid=668e6f1ca58b939eac0de0ac&date=2024-07-19
  getAllDoctorList: {
    url: 'user/doctor-list/doctor',
    service: 'ms1',
  },
  createDoctorSlotApi: {
    url: 'doctor-slot/doctor-slot-create', // doctorId
    service: 'ms1',
  },
  updateDoctorSlotApi: {
    url: 'doctor-slot/doctor-slot-update', // doctorId
    service: 'ms1',
  },
  doctorTimeSlotDelete: {
    url: 'doctor-slot/delete',
    service: 'ms1',
  }, // :id

  // doctor consult
  pastMedicalCreateApi: {
    url: 'medical-history/pastmedical-history/create', //:patientid
    service: 'ms1',
  },
  pastMedicalGetApi: {
    url: 'medical-history/pastmedical-history/list', //:patientid
    service: 'ms1',
  },
  pastSurgeryCreateApi: {
    url: 'medical-history/past-surgery/create', //:patientid
    service: 'ms1',
  },
  getPatientPastSurgeryListApi: {
    url: 'medical-history/past-surgery/list', //:patientid
    service: 'ms1',
  },
  pastPospitalizationCreateApi: {
    url: 'medical-history/past-hospitalization/create', //:patientid
    service: 'ms1',
  },
  pastHospitalizationListApi: {
    url: 'medical-history/past-hospitalization/list', //:patientid
    service: 'ms1',
  },
  pastPersonalSocialCreateApi: {
    url: 'medical-history/personalsocial-history/create', //:patientid
    service: 'ms1',
  },
  pastPersonalSocialGetApi: {
    url: 'medical-history/personalsocial-history/list', //:patientid
    service: 'ms1',
  },
  pastFamilyhistoryofCreateApi: {
    url: 'medical-history/familyhistoryof-disease/create', //:patientid
    service: 'ms1',
  },
  pastFamilyDiseaseGetApi: {
    url: 'medical-history/familyhistoryof-disease/list', //:patient,
    service: 'ms1',
  },
  pastBodyPainCreateApi: {
    url: 'medical-history/bodypain-daigram/create', //:patientid
    service: 'ms1',
  },
  pastBodyPainListApi: {
    url: 'medical-history/bodypain-daigram/list', //:patient,
    service: 'ms1',
  },
  pastAttachmentCreateApi: {
    url: 'medical-history/attachment/create', //:patientid
    service: 'ms1',
  },
  getAttachmentListApi: {
    url: 'medical-history/attachment/list', //:patientid
    service: 'ms1',
  },
  //appointment/upload-appointment-document
  doctorUpcomingConsults: {
    url: 'user/doctor-schedule-consults',
    service: 'ms1',
  },
  doctorPastConsults: {
    url: '/user/pasttoday-consults-list',
    service: 'ms1',
  },
  doctorDashboardMetrics: {
    url: '/user/doctor-schedule-counts/:id',
    service: 'ms1',
  },
  onCallSymptomsTabAdd: {
    url: '/patient-details/symptoms/create',
    service: 'ms1',
  }, // :id
  onCallTeleExaminationTabAdd: {
    url: '/patient-details/tele-examination/create',
    service: 'ms1',
  }, // :id
  onCallInvestigationTabAdd: {
    url: '/patient-details/investigation/create',
    service: 'ms1',
  }, // :id
  onCallDiagnosisTabAdd: {
    url: '/patient-details/create-patient-diagnosis',
    service: 'ms1',
  }, // :id

  getDiagonisisListApi: {
    url: 'patient-details/list-patient-diagnosis', // id
    service: 'ms1',
  },
  getVitalsListApi: {
    url: 'vital/list', // :id
    service: 'ms1',
  },
  getVitalsListbyAppIdApi: {
    url: 'appointment/vitals-list', // :id
    service: 'ms1',
  },
  getSymptomsListApi: {
    url: 'patient-details/symptoms/list', // patientid
    service: 'ms1',
  },
  getInvestigationListApi: {
    url: 'patient-details/investigation/list', // patientid
    service: 'ms1',
  },
  getTeleCommmunicationListApi: {
    url: 'patient-details/tele-examination/list', // patientid
    service: 'ms1',
  },
  updatePrescriptionListApi: {
    url: 'appointment/update-prescription', // :id
    service: 'ms1',
  },
  getmasterListApi: {
    url: 'master/drugtype-list',
    service: 'ms1',
  },
  updateDoctorStatus: {
    url: 'user/update-status', // /:id
    service: 'ms1',
  },

  pastConsultsDetailsListApi: {
    url: 'user/past-consults-list', // /:id
    service: 'ms1',
  },
  pastDoctorConsultsListApi: {
    url: 'appointment/appointment-completed', // /:id
    service: 'ms1',
  },
  // /api/user/doctor-profile-update/:id
  upadteProfileDetailsApi: {
    url: 'user/doctor-profile-update', // /:id
    service: 'ms1',
  },
  // /api/master/route-list
  getRouteListApi: {
    url: 'master/route-list',
    service: 'ms1',
  },
  getMedicinesListApi: {
    url: 'master/pharmaceutical-list',
    service: 'ms1',
  },
  testVideoCall: {
    url: 'appointment/video-call',
    service: 'ms1',
  },
  disconnectVideoCall: {
    url: 'appointment/video-call-statusupdate',
    service: 'ms1',
  },
  prescriptionPreviewApi: {
    url: 'appointment/prescription-preview/list', // :id
    service: 'ms1',
  },
  screenShotSave: {
    url: 'appointment/upload-videocall-screenshot', // :id
    service: 'ms1',
  },
  videoCallEnd: {
    url: 'appointment/video-call-end', // :id
    service: 'ms1',
  },
  vedioRecordingApi: {
    url: 'appointment/upload-videocall-screenrecording', // :id
    service: 'ms1',
  },
  //
  sendToPatientApi: {
    url: 'appointment/prescription-sent-to-patient', // :id
    service: 'ms1',
  },
  redFlagApi: {
    url: 'appointment/red-flag', // :id
    service: 'ms1',
  },
  getPatientListApi: {
    url: 'appointment/live-patient-list',
    service: 'ms1',
  },
};
