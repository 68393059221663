import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  pastHospitalizationSchema,
  pastHospitalizationYesSchema,
} from './medicalHistoryModelSchema';
import { ApiServiceContext, yup } from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import ErrorMessage from '../../../../../../components/errorMessage';
import { onlyAlphabet } from '../../../../../../utils/patterns/regex.pattern';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DateInput } from './inputDateTextFormat';
const initialValues = {
  hospitalization: 'No',
  details: [{ date: '', provide_details: '' }],
};
const validationSchema = yup.object().shape({
  hospitalization: yup.string().required('Surgeries field is required'),
});
const detailsSchema = yup.object().shape({
  details: yup.array().of(
    yup.object().shape({
      date: yup.string().required('Date is required'),
      provide_details: yup.string().required('Details is required'),
    }),
  ),
});
interface HospitilizationProps {
  onSave?: Function;
}
let watchHospitalization = 'No';

const Hospitilization: React.FC<HospitilizationProps> = ({
  onSave = () => {},
}) => {
  const { getData, putData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const mainSchema =
    watchHospitalization === 'Yes'
      ? validationSchema.concat(detailsSchema)
      : validationSchema;

  const methods = useForm({
    resolver: yupResolver(mainSchema),
    defaultValues: initialValues,
  });
  const patient_Id = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details',
  });
  watchHospitalization = watch('hospitalization');
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        pasthospitalization: data.hospitalization === 'Yes' ? 'yes' : 'no',
        hospitalizations: data.details.map((val: any) => {
          return {
            date: moment(val.date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
            provide_details: val.provide_details,
          };
        }),
      };
      let url = end_points.pastPospitalizationCreateApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await postData(url, payload);
      if (response.status === 200) {
        onSave();
        //
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const getPastHospitilizationList = async () => {
    try {
      showLoader();
      let url = end_points.pastHospitalizationListApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        // setPastHospitilizationList(response.data.data);
        const resVal = response.data?.data?.[0];
        setValue(
          'hospitalization',
          resVal.pasthospitalization === 'yes' ? 'Yes' : 'No',
        );
        setValue(
          'details',
          resVal.hospitalizations.map((val: any) => {
            return {
              date: moment(val.date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
              provide_details: val.provide_details,
            };
          }),
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getPastHospitilizationList();
  }, [patient_Id]);
  return (
    <div
      className="tab-pane fade"
      id="past_hospitalization"
      role="tabpanel"
      aria-labelledby="past_hospitalization"
    >
      <div className="row">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12">
              <div className="card medical-history-card">
                <div className="card-body">
                  <div className="past-surgeries past-medical-info">
                    <ul className="nav">
                      <li>
                        <h6>Past Hospitalization</h6>
                      </li>
                      <li>
                        <div className="custom-radio patient-radio-btn">
                          <Controller
                            name="hospitalization"
                            control={control}
                            render={({ field }) => (
                              <>
                                <label className="custom_radio">
                                  <input
                                    {...field}
                                    type="radio"
                                    value="Yes"
                                    checked={field.value === 'Yes'}
                                  />
                                  <span className="checkmark" /> Yes
                                </label>
                                <label className="custom_radio">
                                  <input
                                    {...field}
                                    type="radio"
                                    value="No"
                                    checked={field.value === 'No'}
                                    onClick={() => {
                                      reset();
                                      clearErrors();
                                      remove();
                                    }}
                                  />
                                  <span className="checkmark" /> No
                                </label>
                              </>
                            )}
                          />
                          <ErrorMessage value={errors.hospitalization} />
                        </div>
                      </li>
                    </ul>
                  </div>
                  {watchHospitalization === 'Yes' && (
                    <div className="past-surgeries past-surgeries-left">
                      {fields.map((item: any, index: number) => (
                        <div key={item} className="hospitalization-info">
                          <ul className="nav hospitalization-nav">
                            <li>
                              <div className="hospitalization-grid hospitalization-date">
                                <span>Date</span>
                                <Controller
                                  name={`details[${index}].date`}
                                  control={control}
                                  render={({ field }) => (
                                    <div className="d-flex flex-column">
                                      <DateInput
                                        field={field}
                                        placeholder={'DD/MM/YYYY'}
                                        className={`form-control datetimepicker ${
                                          errors.details?.[index]?.date
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        controlName={`details[${index}].date`}
                                      />
                                      <ErrorMessage
                                        className="mt-0"
                                        value={errors.details?.[index]?.date}
                                      />
                                    </div>
                                    // <input
                                    //   {...field}
                                    //   type="text"
                                    // className={`form-control datetimepicker ${
                                    //   errors.details?.[index]?.date
                                    //     ? 'is-invalid'
                                    //     : ''
                                    // }`}
                                    //   value={field.value ? field.value : null}
                                    //   onChange={(e) => {
                                    //     const date = e.target.value;

                                    //     field.onChange(date);
                                    //     trigger(`details[${index}].date`);
                                    //   }}
                                    //   onBlur={() =>
                                    //     trigger(`details[${index}].date`)
                                    //   }
                                    //   placeholder="MM/YYYY"
                                    // />
                                  )}
                                />
                              </div>
                            </li>
                            <li>
                              <div className="hospitalization-grid">
                                <span>Provide Details</span>

                                <Controller
                                  name={`details[${index}].provide_details`}
                                  control={control}
                                  render={({ field }) => (
                                    <div className="d-flex flex-column">
                                      <input
                                        {...field}
                                        type="text"
                                        className={`form-control ${
                                          errors.details?.[index]
                                            ?.provide_details
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        onBlur={() =>
                                          trigger(
                                            `details[${index}].provide_details`,
                                          )
                                        }
                                        onKeyDown={(event) => {
                                          const regex = onlyAlphabet;
                                          if (
                                            !regex.test(event.key) &&
                                            event.key !== 'Backspace'
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        maxLength={255}
                                        placeholder="Typhoid"
                                      />
                                      <ErrorMessage
                                        value={
                                          errors.details?.[index]
                                            ?.provide_details
                                        }
                                      />
                                    </div>
                                  )}
                                />
                              </div>
                            </li>
                            <li>
                              {fields.length > 1 &&
                                fields.length === index + 1 && (
                                  <button
                                    type="button"
                                    className="btn btn-danger p-2"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                )}
                            </li>
                          </ul>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          append({
                            date: '',
                            provide_details: '',
                          })
                        }
                      >
                        Add
                      </button>
                    </div>
                  )}
                  <div className="past-medical-btn">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Hospitilization;
