import React from 'react';
import { useRef, useEffect, useState } from 'react';
import { PulseGraph } from './pulseGraph';

const ContinuousAudioPlayer = ({ newAudioArray = [] }) => {
  // console.log(newAudioArray, 'newAudioArray from callback');

  const audioContextRef = useRef<any>(null);
  const scriptNodeRef = useRef<any>(null);
  const audioQueueRef = useRef([]);

  useEffect(() => {
    // Initialize the AudioContext and ScriptProcessorNode
    if (!audioContextRef.current) {
      const audioContext: any = new (window.AudioContext ||
        window.webkitAudioContext)();
      const scriptNode = audioContext.createScriptProcessor(4096, 1, 1);

      scriptNode.onaudioprocess = (event: any) => {
        const outputBuffer = event.outputBuffer.getChannelData(0);

        for (let i = 0; i < outputBuffer.length; i++) {
          if (audioQueueRef.current.length > 0) {
            outputBuffer[i] = audioQueueRef.current.shift(); // Play from the queue
          } else {
            outputBuffer[i] = 0; // Silence if the queue is empty
          }
        }
      };

      scriptNode.connect(audioContext.destination);
      audioContextRef.current = audioContext;
      scriptNodeRef.current = scriptNode;
    }

    return () => {
      // Cleanup on component unmount
      if (audioContextRef?.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // console.log(newAudioArray, 'newAudioArray');
    console.count('callBack inside Player');

    if (newAudioArray && Array.isArray(newAudioArray)) {
      audioQueueRef.current.push(...newAudioArray);
    }
  }, [newAudioArray]);

  return (
    <div>
      <PulseGraph audioArray={newAudioArray} />
    </div>
  ); // This component doesn't render anything visible
};

export default ContinuousAudioPlayer;
