import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PrimeDataTable from '../../../../components/dataTable';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import { routes } from '../../../../utils/routes';
import { ApiServiceContext } from '../../../../utils/shared.module';
import SearchFromApi from '../../../../components/search';
import { setEditData } from '../../../../core/redux/commonSlice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

const FrontdeskDoctorsList = () => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      header: 'Name',
      field: 'fullname',
      key: 'fullname',
      sortField: 'fullname',
    },
    {
      header: 'Phone No.',
      field: 'mobile',
      key: 'mobile',
      sortField: 'mobile',
    },
    {
      header: 'Email ID',
      field: 'email',
      key: 'email',
      sortField: 'email',
    },
    {
      header: 'Qualification',
      field: 'degree',
      key: 'degree',
      sortField: 'degree',
    },
    {
      header: 'Registration No.',
      field: 'doctor_reg_no',
      key: 'doctor_reg_no',
      sortField: 'doctor_reg_no',
    },
    {
      header: 'Action',
      sortable: false,
      body: (rowData: any) => (
        <div className="data-slot">
          <Tooltip title="View" color="grey">
            <a onClick={() => handleEdit(rowData)}>
              <i className="feather icon-eye"></i>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];
  const handleEdit = (data: any) => {
    dispatch(setEditData(data));
    navigate(routes.frontDeskCalendar.path);
  };
  const getDoctorsList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.getAllDoctorList.url;
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      // if (filterType) {
      //   url += `&view=${filterType?.toLocaleLowerCase()}`;
      // }
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data);
        setTotalRecords(response?.data?.total_records);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getDoctorsList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          <div className="page-header">
            <h4 className="page-title">Doctor List</h4>
            <div className="page-list">
              <ul className="nav">
                <li>
                  {/* <div className="page-header-btn">
                    <Link to="#" className="btn btn-gray">
                      Search
                    </Link>
                  </div> */}
                  <SearchFromApi callback={handleSearch} />
                </li>
              </ul>
            </div>
          </div>
          {/* /Page Header */}
          {/* Doctors Table */}
          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
            // onClickNavigate={() => {
            //   navigate(routes.frontDeskCalendar.path);
            // }}
          />
          {/* /Doctors Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default FrontdeskDoctorsList;
