import React, { useContext, useEffect, useState } from 'react';
import Symptoms from './symptoms';
import Vitals from './vitals';
import TeleExaminationHistory from './teleExaminationHistory';
import InvestigationHistory from './investigationHistory';
import DiagonosisHistory from './diagonosisHistory';
import PrescriptionHistory from './prescriptionHistory';
import { useSelector } from 'react-redux';
import { SpinnerContext } from '../../../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../../../utils/shared.module';
import { end_points } from '../../../../../services/end_point/end_points';
import { capitalizeWords } from '../../../../../utils/constant';

function MainMenuAppointment() {
  const patient_Id: any =
    // '66ab1348b0a63fdce98e0a6d';
    useSelector((state: any) => state.common.editData?.patient?._id);
  const appoitmentId: any =
    useSelector(
      (state: any) =>
        state?.common?.editData?.patient?.appointments?.[0]?._id ||
        state?.common?.editData?.patient?.appointments?._id,
    ) || '';
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData } = useContext(ApiServiceContext);
  const [medicalHistory, setMedicalHistory] = useState<any>({
    symptomresponse: [],
    vitlasresponse: [],
    diagonisisresponse: [],
    telecommunicationresponse: [],
    investigationresponse: [],
  });
  // // console.log(medicalHistory, 'medicalHistorysymptoms');

  const getUrl = (url: any, id: any) => {
    if (id) {
      url += `/${id}`;
    }
    return url;
  };
  const getPatientHistory = async () => {
    try {
      showLoader();

      const symptomresponse = await getData(
        getUrl(end_points.getSymptomsListApi.url, patient_Id),
      );
      const vitlasresponse = await getData(
        getUrl(end_points.getVitalsListbyAppIdApi.url, appoitmentId),
      );
      const diagonisisresponse = await getData(
        getUrl(end_points.getDiagonisisListApi.url, patient_Id),
      );
      const telecommunicationresponse = await getData(
        getUrl(end_points.getTeleCommmunicationListApi.url, patient_Id),
      );
      const investigationresponse = await getData(
        getUrl(end_points.getInvestigationListApi.url, patient_Id),
      );
      // const bodyPainDiagramResponse = await getData(
      //   getUrl(end_points.pastBodyPainListApi.url),
      // );
      // const attachmentsResponse = await getData(
      //   getUrl(end_points.getAttachmentListApi.url),
      // );

      if (symptomresponse.status === 200) {
        const medicalHistory: any =
          symptomresponse?.data?.data?.[0]?.symptoms || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          symptomresponse: medicalHistory,
        }));
      }
      if (vitlasresponse.status === 200) {
        const newHistory = vitlasresponse.data?.data || {};

        setMedicalHistory((prev: any) => ({
          ...prev,
          vitlasresponse: newHistory,
        }));
      }
      if (telecommunicationresponse.status === 200) {
        const res =
          telecommunicationresponse?.data?.data?.tele_examination || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          telecommunicationresponse: res,
        }));
      }
      if (diagonisisresponse.status === 200) {
        const resVal = diagonisisresponse.data?.data || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          diagonisisresponse: resVal,
        }));
      }
      if (investigationresponse.status === 200) {
        const labelRes =
          investigationresponse.data?.data?.[0]?.investigation || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          investigationresponse: labelRes,
        }));
      }
      // if (bodyPainDiagramResponse.status === 200) {
      //   const bodyPainHistory =
      //     bodyPainDiagramResponse.data.data?.label?.[0]?.name || [];
      //   setMedicalHistory((prev: any) => ({
      //     ...prev,
      //     pastBodyPains: bodyPainHistory,
      //   }));
      // }
      // if (attachmentsResponse.status === 200) {
      //   const attachment = attachmentsResponse.data?.data || [];
      //   // ((100 / Number(response.data?.data.length))?.toFixed(2));
      //   setMedicalHistory((prev: any) => ({
      //     ...prev,
      //     patientAttachments: attachment,
      //   }));
      // }
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getPatientHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_Id]);
  return (
    <div className="col-md-12">
      {/* Appointment Tabs */}
      <div className="appointment-tabs appointment-details-tabs">
        <ul className="nav nav-tabs" id="appointmentTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              href="#symptoms_tab"
              className="nav-link active"
              id="symptoms-tab"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="symptoms_tab"
              aria-selected="true"
            >
              Symptoms
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#vitals_tab"
              className="nav-link"
              id="vitals-tab"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="vitals_tab"
              aria-selected="false"
            >
              Vitals
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#tele_examination"
              className="nav-link"
              id="tele-examination"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="tele_examination"
              aria-selected="false"
            >
              Tele Examination
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#investigation_tab"
              className="nav-link"
              id="investigation-tab"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="investigation_tab"
              aria-selected="false"
            >
              Investigation
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#diagnosis_tab"
              className="nav-link"
              id="diagnosis-tab"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="diagnosis_tab"
              aria-selected="false"
            >
              Diagnosis
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#prescription_tab"
              className="nav-link"
              id="prescription-tab"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="prescription_tab"
              aria-selected="false"
            >
              Prescription
            </a>
          </li>
        </ul>
      </div>
      {/* /Appointment Tabs */}
      {/* Appointment Content */}
      <div className="card">
        <div className="card-body pb-0">
          <div className="tab-content" id="appointmentTabContent">
            {/* Symptoms */}
            <Symptoms details={medicalHistory.symptomresponse} />
            {/* /Symptoms */}
            {/* Vitals */}
            <Vitals details={medicalHistory.vitlasresponse} />
            {/* /Vitals */}
            <TeleExaminationHistory
              details={medicalHistory.telecommunicationresponse}
            />
            <InvestigationHistory
              details={medicalHistory.investigationresponse}
            />
            <DiagonosisHistory detials={medicalHistory.diagonisisresponse} />
            <PrescriptionHistory />
          </div>
        </div>
      </div>
      {/* /Appointment Content */}
    </div>
  );
}

export default MainMenuAppointment;
