import React, { useContext, useEffect, useState } from 'react';
import PastMedicalHistory from './pastMedicalHistory';
import PastSurgeries from './pastSurgeries';
import BodyPainDiagram from './bodyPainDiagram';
import PatientAttachments from './patientAttachments';
import { useSelector } from 'react-redux';
import { capitalizeWords } from '../../../../../utils/constant';
import { end_points } from '../../../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../components/spinner/spinner';
import PastHositalization from './pastHositalization';
import PastSocial from './pastSocial';
import PastFamilyHistory from './pastFamilyHistory';

function MedicalHistory() {
  const patient_I: any =
    // '66ab1348b0a63fdce98e0a6d';
    useSelector((state: any) => state.common?.editData?.patient?._id);
  const pastPatientDetails: any = useSelector(
    (state: any) => state?.common?.pastData?.patient?.patient?._id,
  );

  const patient_Id: any = pastPatientDetails ? pastPatientDetails : patient_I;
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData } = useContext(ApiServiceContext);
  const [medicalHistory, setMedicalHistory] = useState<any>({
    pastMedical: [],
    pastSurgeries: [],
    pastHospitalizations: [],
    pastPersonalSocial: [],
    pastFamilyDiseases: [],
    pastBodyPains: [],
    patientAttachments: [],
  });

  const getUrl = (url: any) => {
    if (patient_Id) {
      url += `/${patient_Id}`;
    }
    return url;
  };
  const getPatientHistory = async () => {
    try {
      showLoader();

      const medicalresponse = await getData(
        getUrl(end_points.pastMedicalGetApi.url),
      );
      const surgeryResponse = await getData(
        getUrl(end_points.getPatientPastSurgeryListApi.url),
      );
      const hospitizationResponse = await getData(
        getUrl(end_points.pastHospitalizationListApi.url),
      );
      const socialResponse = await getData(
        getUrl(end_points.pastPersonalSocialGetApi.url),
      );
      const familyResponse = await getData(
        getUrl(end_points.pastFamilyDiseaseGetApi.url),
      );
      const bodyPainDiagramResponse = await getData(
        getUrl(end_points.pastBodyPainListApi.url),
      );
      const attachmentsResponse = await getData(
        getUrl(end_points.getAttachmentListApi.url),
      );

      if (medicalresponse.status === 200) {
        const medicalHistory =
          medicalresponse.data?.data?.label?.[0]?.pasthistory?.map(
            (item: any) => {
              const itemName = capitalizeWords(item.name);
              if (itemName === 'Seizure') {
                return {
                  name: itemName,
                  isChecked: item.is_check,
                  date: item.since,
                  frequency: item.frequency,
                  per: item.per,
                };
              } else
                return {
                  name: itemName,
                  isChecked: item.is_check,
                  date: item.since,
                };
            },
          ) || [];

        setMedicalHistory((prev: any) => ({
          ...prev,
          pastMedical: medicalHistory,
        }));
      }
      if (surgeryResponse.status === 200) {
        const newHistory = surgeryResponse.data?.data?.label || [];

        setMedicalHistory((prev: any) => ({
          ...prev,
          pastSurgeries: newHistory,
        }));
      }
      if (socialResponse.status === 200) {
        const res = socialResponse?.data?.data?.label?.[0] || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          pastPersonalSocial: res,
        }));
      }
      if (hospitizationResponse.status === 200) {
        const resVal = hospitizationResponse.data?.data?.[0] || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          pastHospitalizations: resVal,
        }));
      }
      if (familyResponse.status === 200) {
        const labelRes = familyResponse.data?.data?.label?.[0] || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          pastFamilyDiseases: labelRes,
        }));
      }
      if (bodyPainDiagramResponse.status === 200) {
        const bodyPainHistory =
          bodyPainDiagramResponse.data.data?.label?.[0]?.name || [];
        setMedicalHistory((prev: any) => ({
          ...prev,
          pastBodyPains: bodyPainHistory,
        }));
      }
      if (attachmentsResponse.status === 200) {
        const attachment = attachmentsResponse.data?.data || [];
        // ((100 / Number(response.data?.data.length))?.toFixed(2));
        setMedicalHistory((prev: any) => ({
          ...prev,
          patientAttachments: attachment,
        }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getPatientHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_Id]);
  return (
    <div className="col-md-12">
      {/* Medical Tabs */}
      <div className="appointment-tabs">
        <ul className="nav nav-tabs" id="medicalTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              href="#past_medical"
              className="nav-link active"
              id="past-medical"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="past_medical"
              aria-selected="true"
            >
              Past Medical History
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#past_surgeries"
              className="nav-link"
              id="past-surgeries"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="past_surgeries"
              aria-selected="false"
            >
              Past Surgeries
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#past_hospitalization"
              className="nav-link"
              id="past-hospitalization"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="past_hospitalization"
              aria-selected="false"
            >
              Past Hospitalization
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#social_history"
              className="nav-link"
              id="social-history"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="social_history"
              aria-selected="false"
            >
              Personal Social history
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#family_history"
              className="nav-link"
              id="family-history"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="family_history"
              aria-selected="false"
            >
              Family history of disease
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#pain_diagram"
              className="nav-link"
              id="pain-diagram"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="pain_diagram"
              aria-selected="false"
            >
              Body Pain Diagram
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#attachment_tab"
              className="nav-link"
              id="attachment-tab"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="attachment_tab"
              aria-selected="false"
            >
              Attachment
            </a>
          </li>
        </ul>
      </div>
      {/* /Medical Tabs */}
      {/* Medical History */}
      <div className="tab-content" id="medicalTabContent">
        {/* Past Medical History */}
        <PastMedicalHistory
          patient_Id={patient_Id}
          details={medicalHistory.pastMedical}
        />
        {/* /Past Medical History */}
        {/* Past Surgeries */}
        <PastSurgeries
          patient_Id={patient_Id}
          details={medicalHistory.pastSurgeries}
        />
        <PastHositalization details={medicalHistory.pastHospitalizations} />
        <PastSocial details={medicalHistory.pastPersonalSocial} />
        <PastFamilyHistory details={medicalHistory.pastFamilyDiseases} />
        {/* /Past Surgeries */}
        {/* Body Pain Diagram */}
        <BodyPainDiagram details={medicalHistory.pastBodyPains} />
        {/* /Body Pain Diagram */}
        {/* Attachment */}
        <PatientAttachments details={medicalHistory.patientAttachments} />
        {/* /Attachment */}
      </div>
      {/* /Medical History */}
    </div>
  );
}

export default MedicalHistory;
