import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { edit02, edit01, exclamationIcon } from '../../../../utils/imagepath';
import { routes } from '../../../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import {
  setAssociateToData,
  setPathFrom,
} from '../../../../core/redux/commonSlice';

const FrontDeskView = () => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const editData = useSelector((state: any) => state?.common?.editData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeModel = useRef<any>(null);
  const [listData, setListData] = useState<any>([]);
  const getViewData = async () => {
    try {
      showLoader();
      let url = end_points.adminPagesGetById.url;
      url += `/${editData?._id}`;
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getViewData();
  }, []);

  const navigateToAssociate = (data: any) => {
    if (data === 'associate') {
      dispatch(setPathFrom('front-desk'));
      dispatch(setAssociateToData(editData));
      navigate(routes.kioskAssociate.path);
    } else {
      closeModel.current.click();
      dispatch(setPathFrom('front-desk'));
      dispatch(setAssociateToData(editData));
      navigate(routes.kioskAssociate.path);
    }
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Front Desk */}
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card edit-card">
                <div className="card-body pb-0">
                  <div className="edit-icon">
                    <Link to={routes.frontDeskEdit.path}>
                      <i className="fa-regular fa-pen-to-square" />
                    </Link>
                  </div>
                  <div className="edit-inner-body">
                    <div className="row">
                      <div className="col-lg-5 d-flex">
                        <div className="edit-inner-box w-100">
                          <div className="edit-header">
                            <h4>{listData?.fullname}</h4>
                            <ul className="nav">
                              <li>#{listData?.reg_id}</li>
                              {listData?.status === 'active' ? (
                                <li>
                                  <p>
                                    <span className="status-active" />
                                    Active
                                  </p>
                                </li>
                              ) : (
                                <li>
                                  <p>
                                    <span className="status-inactive" />
                                    In Active
                                  </p>
                                </li>
                              )}
                            </ul>
                            {listData?.kioskDetails?.length > 0 && (
                              <ul className="nav re-associate-nav">
                                <li>
                                  Kiosk:{' '}
                                  <span>{listData?.kioskDetails[0]?.name}</span>
                                </li>
                                <li>
                                  Kiosk ID:{' '}
                                  <span>
                                    {listData?.kioskDetails[0]?.reg_id}
                                  </span>
                                </li>
                              </ul>
                            )}
                            <div className="associate-btn">
                              {listData?.kioskDetails?.length === 0 ? (
                                <a
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    navigateToAssociate('associate');
                                  }}
                                >
                                  Associate Kiosk
                                </a>
                              ) : (
                                <Link
                                  to="#"
                                  className="btn btn-secondary"
                                  data-bs-toggle="modal"
                                  data-bs-target="#associate_modal"
                                >
                                  Re-Associate Kiosk
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 d-flex">
                        <div className="edit-details w-100">
                          <div className="edit-list edit-list-info">
                            <ul>
                              <li>
                                <img src={edit02} alt="Icon" />
                                <a href={`mailto:${listData?.email}`}>
                                  {listData?.email}
                                </a>
                              </li>
                              <li>
                                <img src={edit01} alt="Icon" />
                                <a href={`tel:${listData?.mobile}`}>
                                  {listData?.mobile}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Front Desk */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Associate Kiosk */}
      <div
        className="modal custom-modal fade"
        id="associate_modal"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="re-associate-content">
                <div className="re-associate-text">
                  <img src={exclamationIcon} alt="Icon" />
                  <p>Do you Really want to Re-associate this Front Desk?</p>
                </div>
                <div className="modal-form-btn">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      navigateToAssociate('re-associate');
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                    ref={closeModel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Associate Kiosk */}
    </>
  );
};

export default FrontDeskView;
